import React, { useEffect } from 'react';
import ContentList from '../../components/content-list';
// import data from '../../components/content-list/data.json';
import { MoviesApi } from '../../services/content-apis/movies';
import { GenresApi } from '../../services/content-apis/genres';

interface UserMoviesProps {
  genre: string;
}

const UserMovies = ({ genre }: UserMoviesProps) => {
  const [data, setData] = React.useState<any[]>([]);

  useEffect(() => {
    const getMovies = async () => {
      MoviesApi.getAll(genre).then((response) => {
        setData(response);
      });
    };

    getMovies();
  }, [genre]);

  return (
    <>
      {data &&
        data.length > 0 &&
        data[0]?.carousel &&
        data.map(({ priority, carousel, movies }) => (
          <ContentList
            key={priority}
            priority={priority}
            carousel={carousel}
            list={movies}
            type="movies"
          />
        ))}
    </>
  );
};

export default UserMovies;
