// src/utils/detectOS.ts
export const detectOS = (): string => {
  const userAgent =
    navigator.userAgent || (navigator as any).vendor || (window as any).opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  if (/HarmonyOS/i.test(userAgent)) {
    return 'HarmonyOS';
  }

  return 'unknown';
};
