import React from 'react';
import HubspotContactForm from '../../HubspotContactForm/HubspotContactForm';

const Help: React.FC = () => {
  return (
    <div
      id="help"
      style={{ width: '100%' }}
    >
      <HubspotContactForm />
    </div>
  );
};

export default Help;
