import React from 'react';
import logo from '../../assets/images/logo.png';
import styles from './NotFound.module.css';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className="">
        <img
          className={styles.logo}
          src={logo}
        />
      </div>
      <h1>404 - Page Not Found</h1>
      <p>Sadly, the page you are looking for could not be found.</p>

      <Link to="/sports">Back to Safety...</Link>
    </div>
  );
};

export default NotFound;
