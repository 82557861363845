// src/components/downloadApp/DownloadApp.tsx
import React, { useEffect, useState } from 'react';
import { detectOS } from '../../utils/detectOS';
import './DownloadApp.css';
import beginLogo from '../../assets/images/logos/downloadApp-begin-logo.png';
import downloadAndroid from '../../assets/images/logos/download-android.png';
import downloadIos from '../../assets/images/logos/download-ios.png';
import downloadHuawei from '../../assets/images/logos/download-huawei.png';
import downloadGoogleTv from '../../assets/images/logos/download-googletv.png';

const DownloadApp = () => {
  const [os, setOS] = useState('unknown');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const detectedOS = detectOS();
    setOS(detectedOS);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="download-app-container">
      <div className="overlay">
        <div className="content-container">
          <div className="inner-content-container">
            <img src={beginLogo} alt="Begin Logo" className="logo" />
            <p>
              Stream movies, series, and exclusive originals, anytime, anywhere
              on the go.
            </p>
            <p>
              Download now on the Play Store and experience Premium Streaming
              like never before.
            </p>
            <div className="download-buttons">
              {os === 'Android' && (
                <a
                  href="https://play.google.com/store/apps/details?id=z2c.begin&hl=en_US"
                  rel="noopener noreferrer"
                >
                  <img src={downloadAndroid} alt="Get it on Google Play" />
                </a>
              )}
              {/* {os === 'iOS' && (
                <a
                  href="https://apps.apple.com/pk/app/begin-watch/id6476600897"
                  rel="noopener noreferrer"
                >
                  <img src={downloadIos} alt="Download on the App Store" />
                </a>
              )} */}
              {os === 'HarmonyOS' && (
                <a
                  href="https://appgallery.huawei.com/#/app/C111084511"
                  rel="noopener noreferrer"
                >
                  <img src={downloadHuawei} alt="Download on AppGallery" />
                </a>
              )}
              {os === 'unknown' && (
                <div className="all-buttons">
                  <a
                    href="https://play.google.com/store/apps/details?id=z2c.begin&hl=en_US"
                    rel="noopener noreferrer"
                  >
                    <img src={downloadAndroid} alt="Get it on Google Play" />
                  </a>
                  <a
                    href="https://apps.apple.com/pk/app/begin-watch/id6476600897"
                    rel="noopener noreferrer"
                  >
                    <img src={downloadIos} alt="Download on the App Store" />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/#/app/C111084511"
                    rel="noopener noreferrer"
                  >
                    <img src={downloadHuawei} alt="Download on AppGallery" />
                  </a>
                </div>
              )}
              <div>
                {' '}
                <a>
                  <img src={downloadGoogleTv} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
