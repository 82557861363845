import React from 'react';

import styles from '../SettingsModules.module.css';
import MySubscriptions from '../../UserSettings/partials/my-subscriptions';

const SubscriptionsSettings: React.FC = () => {
  // Add your component logic here

  return (
    <div className={styles.childModule}>
      <MySubscriptions />
    </div>
  );
};

export default SubscriptionsSettings;
