import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import style from '../auth-child.module.css';

import ProgressDots from '../../../components/progress-dots/progress-dots';
import { useNavigate, useParams } from 'react-router-dom';
import BeginButton from '../../../components/begin-button/begin-button';
import {
  SubscriptionsAPI,
  getAllSubscriptions,
} from '../../../services/subscriptions';
import {
  SubscriptionsDTO,
  subscriptionDetails,
} from '../../../types/remote-dtos/subscriptions.dto';

import internationalIcon from '../../../assets/images/subscriptions/international-icon-2.png';
import localIcon from '../../../assets/images/subscriptions/Basic.png';

import { SubscriptionImages } from '../../../utils/SubscriptionImages';
import { SubscriptionFlter } from '../../../types/local-models/subscription.models';
import { tvShowsApi } from '../../../services/content-apis/tvShows';

import { FaChevronLeft } from 'react-icons/fa';
import { MoviesApi } from '../../../services/content-apis/movies';
import { sportsApi } from '../../../services/content-apis/sports';

interface AvailableSubscriptionsProps {
  flowType?: 'getContent' | 'normal';
}

const AvailableSubscriptions: FC<AvailableSubscriptionsProps> = ({
  flowType,
}: AvailableSubscriptionsProps) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formValid, setFormValid] = useState(false);

  const [subscriptionsList, setSubscriptionsList] = useState<
    subscriptionDetails[]
  >([]);

  const [selectedSubscriptionID, setSelectedSubscriptionID] = useState('');
  const [selectedSubscriptions, setSelectedSubscriptions] = useState('');

  const [costs, setCosts] = useState(0);

  const [subscriptionDurations, setSubscriptionDurations] = useState<any[]>([]);

  const params = useParams();

  const [baseURL, setBaseUrl] = useState('');

  useEffect(() => {
    if (window.location.href.includes('live')) {
      setBaseUrl('live');
    } else {
      setBaseUrl('account');
    }

    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);

    dispatch(landingActions.hideToast());
    dispatch(landingActions.showLoader());

    if (flowType == 'getContent' && params.contentType && params.contentID) {
      switch (params.contentType) {
        case 'movies':
          MoviesApi.getMovie(params.contentID, 'movie').then(
            (response: any) => {
              dispatch(landingActions.hideLoader());
              if (
                typeof response != 'undefined' &&
                typeof response.streamLink != 'undefined' &&
                response.streamLink.length > 0 &&
                typeof response.streamLink[0].subscriptions != 'undefined'
              ) {
                setSubscriptionsList(response.streamLink[0].subscriptions);
              }
            }
          );
          break;
        case 'tv-shows':
          tvShowsApi
            .getTVShow(params.contentID, 'tv-show')
            .then((response: any) => {
              dispatch(landingActions.hideLoader());
              if (
                typeof response != 'undefined' &&
                typeof response.streamLink != 'undefined' &&
                response.streamLink.length > 0 &&
                typeof response.streamLink[0].subscriptions != 'undefined'
              ) {
                setSubscriptionsList(response.streamLink[0].subscriptions);
              }
            });
          break;
        case 'sports':
          sportsApi
            .getTVShow(params.contentID, 'tv-show')
            .then((response: any) => {
              dispatch(landingActions.hideLoader());
              if (
                typeof response != 'undefined' &&
                typeof response.streamLink != 'undefined' &&
                response.streamLink.length > 0 &&
                typeof response.streamLink[0].subscriptions != 'undefined'
              ) {
                setSubscriptionsList(response.streamLink[0].subscriptions);
              }
            });
          break;
        case 'episode':
          if (params.seasonID && params.contentID) {
            sportsApi
              .getEpisode(params.seasonID, params.contentID)
              .then((response: any) => {
                dispatch(landingActions.hideLoader());
                if (typeof response != 'undefined' && response.length > 0) {
                  setSubscriptionsList(response[0].subscriptions);
                }
              });
          }
          break;
        case 'explore':
          break;
        default:
          console.log('Invalid content type');
      }
    } else {
      SubscriptionsAPI.getAll(null).then((response: any) => {
        setSubscriptionsList(response);

        dispatch(landingActions.hideLoader());
      });
    }
  }, [params]);

  const expandAndSelect = (item: subscriptionDetails) => {
    setSelectedSubscriptionID(item.subscriptionID);
    setSelectedSubscriptions(item.subscriptionTitle);

    setCosts(item.subscriptionPricing[0].price);

    setSubscriptionDurations(item.subscriptionPricing);

    setFormValid(true);
  };

  const subscriptionsSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.setItem(
      'selectedSubscriptions',
      JSON.stringify(selectedSubscriptions)
    );

    localStorage.setItem('selectedSubscriptionID', selectedSubscriptionID);

    localStorage.setItem('subscriptionList', JSON.stringify(subscriptionsList));
    localStorage.setItem('subscriptionCosts', JSON.stringify(costs));
    localStorage.setItem(
      'subscriptionDurations',
      JSON.stringify(subscriptionDurations)
    );

    if (subscriptionDurations.length > 1) {
      navigate(`/${baseURL}/select-subscription-duration`);
    } else {
      localStorage.setItem(
        'selectedBillingCycle',
        subscriptionDurations[0].type
      );
      // navigate(`/${baseURL}/payment-methods`);
      navigate('/account/payment');
    }
  };

  return (
    <div className={style.authContainer}>
      <form onSubmit={subscriptionsSubmit} className={style.form}>
        <div className={style.authContent}>
          <h1 className="title">
            <FaChevronLeft
              className={style.backBtn}
              onClick={() => navigate(-1)}
            />
            Packages
          </h1>

          <div className={style.group}>
            <ProgressDots dots={3} activeIndex={0} />
            <div className="preHeading">Packages</div>
            <h1>Choose a package</h1>
            <div className={style.titleLabel}>Please select a package</div>
            <div className={style.separator}></div>
          </div>

          <div className={style.subscriptionDetails}>
            {subscriptionsList.map((entry) => {
              return (
                <div
                  key={entry.subscriptionID}
                  className={style.subscription}
                  onClick={() => {
                    expandAndSelect(entry);
                  }}
                >
                  <div className={style.subscriptionContainerSection}>
                    <img
                      src={
                        // entry.subscriptionTitle == 'International'
                        //   ? internationalIcon
                        //   : localIcon
                        entry.subscriptionIcon
                      }
                      className={style.subscriptionIcon}
                    />

                    <div className={style.subscriptionTitle}>
                      <h1>{entry.subscriptionTitle}</h1>
                      <p>
                        for &nbsp;
                        {Math.min(
                          ...entry.subscriptionPricing.map(
                            (price) => price.price
                          )
                        )}
                        &nbsp;PKR
                      </p>
                    </div>
                    <div className={style.checkbox}>
                      <div
                        className={`${style.checkmark}
                          ${
                            selectedSubscriptions == entry.subscriptionTitle
                              ? style.selectedCheckbox
                              : ''
                          }`}
                      ></div>
                    </div>
                  </div>

                  <div className={style.subscriptionContainerSection}>
                    {SubscriptionImages[
                      entry.subscriptionTitle as keyof typeof SubscriptionImages
                    ]?.map((image: any) => {
                      return <img key={Math.random()} src={image} />;
                    })}
                  </div>

                  {/* <div className={style.viewMore}>
                    <small>
                      <i className="icon-down" />
                      &nbsp; View more
                    </small>
                  </div> */}
                </div>
              );
            })}
          </div>

          <div className={style.buttonGroup}>
            <div className={style.costs}>
              <div className={style.cost}>
                <div className="preHeading">Your Cost</div>
                <h1 className={style.price}>
                  {costs}
                  &nbsp;PKR
                </h1>
              </div>
            </div>

            <BeginButton
              type="submit"
              disabled={!formValid}
              className="primary"
              to=""
              datalabel="Next"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AvailableSubscriptions;
