import api, { Headers } from './config/api';

const url = 'accounts/profiles';
const avatarUrl = 'masterdata/avatars';

export const ProfileAPI = {
  getAll: async () => {
    const payload = {
      operation: 'list',
      payload: {
        accountID: localStorage.getItem('accountID'),
        select: [],
        filter: {
          acctProfileStatus: 'active',
        },
      },
    };

    try {
      const res = await api.post(url, payload);
      return res.data.data;
    } catch (error) {
      window.location.reload();
      console.log(error);
    }
  },
  getProfile: async (profileID: string) => {
    const payload = {
      operation: 'read',
      payload: {
        accountID: localStorage.getItem('accountID'),
        profileID: profileID,
        select: [],
        filter: {
          acctProfileStatus: 'active',
        },
      },
    };

    try {
      const res = await api.post(url, payload);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAvatars: async () => {
    const payload = {
      operation: 'list',
      masterdata: 'avatars',
      payload: {
        select: [],
        filter: {
          avatarStatus: 'active',
        },
      },
    };

    try {
      const res = await api.post(avatarUrl, payload);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  add: async (data: any) => {
    const payload = {
      operation: 'create',
      payload: {
        accountID: localStorage.getItem('accountID'),
        attributes: {
          acctProfileName: data.name,
          acctProfileForKids: data.forKids, // true, false
          acctProfileAvatar: data.avatar,
          acctProfileType: data.type,
          acctProfileStatus: 'active', // active, inactive
        },
      },
    };

    try {
      const res = await api.post(url, payload);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  update: async (data: any) => {
    const payload = {
      operation: 'update',
      payload: {
        accountID: localStorage.getItem('accountID'),
        profileID: data.profileID,
        attributes: {
          acctProfileName: data.name,
          acctProfileForKids: data.forKids, // true, false
          acctProfileAvatar: data.avatar,
          acctProfileType: data.type,
          acctProfileStatus: 'active', // active, inactive
        },
      },
    };

    try {
      const res = await api.patch(url, payload);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
  deleteProfile: async (profileID: string) => {
    const payload = {
      operation: 'delete',
      payload: {
        accountID: localStorage.getItem('accountID'),
        profileID: profileID,
      },
    };

    try {
      const res = await api.post(url, payload);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
};
