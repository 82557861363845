import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

let isSupportedBrowser = null;

(async () => {
  isSupportedBrowser = await isSupported();
  if (!isSupportedBrowser) return;
})();

// const firebaseConfig = {
//   apiKey: 'AIzaSyCDVfUwziMq29pgA5uM0eTezD2xdcY_y4w',
//   authDomain: 'begin-web.firebaseapp.com',
//   projectId: 'begin-web',
//   storageBucket: 'begin-web.appspot.com',
//   messagingSenderId: '128555586493',
//   appId: '1:128555586493:web:1c87ecf3337915053538d4',
//   measurementId: 'G-3D0NSPL3KG',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyCAdN0phyv68d8CQFeXCKL57i_euCFtntQ',
  authDomain: 'begin-335c7.firebaseapp.com',
  databaseURL: 'https://begin-335c7-default-rtdb.firebaseio.com/',
  projectId: 'begin-335c7',
  storageBucket: 'begin-335c7.appspot.com',
  messagingSenderId: '25056466686',
  appId: '1:25056466686:web:cb7c971c2e57526fad37af',
  measurementId: 'G-H6ZVPG7BWB',
};

const vapidKey =
  'BGXR2FMXwOYViUIUj7IkMaqxpiKm0WTJOP-br0HTFv_WBd6sBmArqhIHZb0dDFvy8I3bPBhwfGLm8QZRqKUihfw';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

type CallBack = (token: string) => void;

const handleFCM = async (callback: CallBack) => {
  const id = localStorage.getItem('device-id');
  try {
    if (!id) {
      const token = await getToken(messaging, { vapidKey });
      localStorage.setItem('device-id', token);
      callback(token);
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

export const getFirebasePermissions = async (callback: CallBack) => {
  let notify = await Notification.requestPermission();

  if (notify !== 'denied' && notify !== 'granted') {
    notify = await Notification.requestPermission();
  }

  if (notify === 'granted') {
    await handleFCM(callback);
  }
};

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
});
