import { redirect } from 'react-router-dom';
import { getAuthToken } from '../services/Cognito';

export function tokenLoader(): any {
  const token = getAuthToken();

  return typeof token != 'undefined' ? token : null;
}

export function checkAuthLoader(): any {
  const token = getAuthToken();

  if (!token) {
    localStorage.setItem('lastLocation', window.location.pathname);
    window.location.href = '/refresh-token';
    return null;
  } else {
    return token;
  }
}
