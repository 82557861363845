import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router-dom';
import style from './partials.module.css';
import BeginButton from '../../../components/begin-button/begin-button';
interface SideBarProps {
  onClick: () => void;
}

const SideBar = ({ onClick }: SideBarProps) => {
  const location = useLocation();

  const AccountLinks = [
    {
      name: 'My Account',
      link: '#my-account',
    },
    // {
    //   name: 'My Profile',
    //   link: '#my-profile',
    // },
  ];

  const SubscriptionLinks = [
    {
      name: 'My Subscription',
      link: '#my-subscription',
    },
    // {
    //   name: 'My Payments',
    //   link: '#my-payemnt',
    // },
    // {
    //   name: 'My Cards',
    //   link: '#my-card',
    // },
  ];

  const OthersLinks = [
    // {
    //   name: 'Settings',
    //   link: '#settings',
    // },
    {
      name: 'Devices',
      link: '#devices',
    },
    {
      name: 'Help',
      link: '#help',
    },
  ];

  const linkHash = location.hash;
  return (
    <aside className={style.sideMenu}>
      <>
        {AccountLinks.map(({ name, link }, index) => (
          <a
            key={index}
            className={clsx(style.links, linkHash == link && style.active)}
            href={link}
          >
            {name}
          </a>
        ))}
        <hr className={style.divider} />
        {SubscriptionLinks.map(({ name, link }, index) => (
          <a
            key={index}
            className={clsx(style.links, linkHash == link && style.active)}
            href={link}
          >
            {name}
          </a>
        ))}
        <hr className={style.divider} />
        {OthersLinks.map(({ name, link }, index) => (
          <a
            key={index}
            className={clsx(style.links, linkHash == link && style.active)}
            href={link}
          >
            {name}
          </a>
        ))}
        <hr className={style.divider} />
        <BeginButton
          preset="link"
          type="button"
          className="cancel"
          datalabel="Cancel"
          dataiconstart="icon-close"
          onClick={onClick}
        />
      </>
    </aside>
  );
};

export default SideBar;
