import axios from 'axios';
import { appEndpoints } from '../../api-endpoints';
import { getAuthToken } from '../Cognito';

export const Headers = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + getAuthToken(),
  // Platform: 'web',
};

const api = axios.create({
  baseURL: appEndpoints.prod,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + getAuthToken(),
  },
});

const cachedApi = axios.create({
  baseURL: appEndpoints.search,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + getAuthToken(),
  },
})

export default api;
