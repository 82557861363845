import React, { useEffect, useState } from 'react';
import BeginInput from '../../../components/form-input-fields/begin-input';
import style from './partials.module.css';
import { getCustomerDetailsAPI } from '../../../services/customer-details';
import BeginButton from '../../../components/begin-button/begin-button';

interface CustomerDetails {
  email: string;
  phone: string;
}
const MyAccount = () => {
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({
    email: '',
    phone: '',
  });
  useEffect(() => {
    getCustomerDetailsAPI(
      localStorage.getItem('accountID') || '',
      (response) => {
        setCustomerDetails({
          email: response.accountEmail,
          phone: response.accountPhone,
        });
      }
    );
  }, []);

  const submitForm = () => {
    console.log('submit form');
  };

  return (
    <section
      id="my-account"
      className={style.section}
    >
      <h1>My Account</h1>
      <form
        autoComplete="off"
        onSubmit={submitForm}
      >
        <label className={style.label}> My Email</label>
        <div className={style.editInput}>
          <BeginInput
            id="email"
            name="email"
            placeholder=""
            type="email"
            readonly={true}
            value={customerDetails.email}
            disabled
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              console.log(event?.target?.value)
            }
          />
        </div>
        <label className={style.label}> My Mobile</label>

        <div className={style.editInput}>
          <BeginInput
            id="phone"
            name="phone"
            placeholder=""
            type="tel"
            value={customerDetails.phone}
            disabled
            readonly={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              console.log(event?.target?.value)
            }
          />
        </div>

        {/* <label className={style.label}> Current Password</label>

        <div className={style.editInput}>
          <BeginInput
            id="current-password"
            name="current-password"
            placeholder=""
            type="password"
            value={''}
            disabled
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              console.log(event?.target?.value)
            }
          />
        </div>

        <label className={style.label}> New Password</label>

        <div className={style.editInput}>
          <BeginInput
            id="new-password"
            name="new-password"
            placeholder=""
            type="password"
            value={''}
            disabled
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              console.log(event?.target?.value)
            }
          />
        </div>

        <label className={style.label}> Confirm New Password</label>

        <div className={style.editInput}>
          <BeginInput
            id="confirm-password"
            name="confirm-password"
            placeholder=""
            type="password"
            value={''}
            disabled
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              console.log(event?.target?.value)
            }
          />
        </div>

        <div className={style.label}>
          <BeginButton
            className="primary"
            type="submit"
            onClick={submitForm}
            datalabel="Save"
          />
        </div> */}
      </form>
    </section>
  );
};

export default MyAccount;
