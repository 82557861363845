import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import BeginInput from '../../components/form-input-fields/begin-input';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import style from './UserSearch.module.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { getSearchResult } from '../../services/search';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate, useParams } from 'react-router-dom';

import Shimmer from '../../components/shimmer/shimmer';
import ContentDetails2 from '../../components/content-details-2/ContentDetails2';

interface UserSearchProps {
  contentType?: string;
  contentID?: string;
}
const UserSearch: React.FC<UserSearchProps> = ({ contentID, contentType }) => {
  const [searchTerm, setSearch] = useState('');
  const [searchData, setSearchData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const navigate = useNavigate();
  const searchedValue = useDebounce(searchTerm, 500);
  const [isError, setIsError] = useState(false);

  const [contentSelected, setContentSelected] = useState<string>('');

  const params = useParams();

  let timer: number;

  useEffect(() => {
    console.log('use effect triggered!');
    setSearchData([]);

    if (searchedValue) {
      window.clearTimeout(timer);
      setIsLoading(true);
      setIsError(false);
      setSearchCompleted(false);

      timer = window.setTimeout(() => {
        getSearchResult(searchedValue.trim(), (response) => {
          setIsLoading(false);
          setSearchCompleted(true);
          if (
            typeof response !== 'undefined' &&
            typeof response.data !== 'undefined' &&
            typeof response.data.data !== 'undefined'
          ) {
            setSearchData(response?.data?.data);
          } else {
            setIsError(true);
          }
        });
      }, 1000);
    }
  }, [searchedValue]);

  const handleBackClick = () => {
    navigate(-1);
  };
  const goToDetail = (id: string, type: string) => {
    switch (type) {
      case 'movie':
        navigate(`/user-search/movies/${id}`);
        break;
      case 'tvseries':
        navigate(`/user-search/tv-shows/${id}`);
        break;
      case 'sports':
        navigate(`/user-search/sports/${id}`);
        break;
      // case 'explore':
      //   navigate(`/sports/${contentID}`);
      //   break;
      default:
        console.log('Invalid content type');
    }
  };

  const handleClose = () => {
    window.setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  return (
    <main className={style.container}>
      <header>
        <h3
          className={style.heading}
          onClick={handleBackClick}
        >
          <ArrowBackIosIcon /> <span> Search</span>
        </h3>
        <form
          autoComplete="off"
          onSubmit={(event) => event.preventDefault()}
        >
          <BeginInput
            type="search"
            placeholder="Search shows, Movies..."
            name="search"
            id="search"
            value={searchTerm}
            onChange={(event) => setSearch(event.target.value)}
            autoComplete="off"
          />
        </form>
      </header>
      <div className={clsx(style.card)}>
        <section className={`${style.content} ${style.searchContent}`}>
          {searchData &&
            searchData.length > 0 &&
            searchCompleted &&
            searchData.map((item, index) => (
              <div
                key={index}
                className={`${style.contentItem} shimmer`}
                onClick={() => goToDetail(item.SK, item.type)}
              >
                <LazyLoadImage
                  className={clsx(style.contentThumbnail)}
                  width={224}
                  height={317}
                  effect="opacity"
                  placeholder={
                    <Shimmer
                      variant="card"
                      visible={true}
                    />
                  }
                  src={
                    item.images?.find(
                      (content: any) => content.imageType === 'poster'
                    )?.imagePath
                  }
                />
                <div className={style.overlay}>
                  <h2 className={style.title}>{item.originalTitle}</h2>
                </div>
              </div>
            ))}

          <Shimmer
            variant="search"
            visible={isLoading}
          />

          {searchedValue.trim() !== '' &&
            searchData &&
            searchData.length === 0 &&
            searchCompleted &&
            !isError && (
              <div className={style.noResult}>
                <h3>No results found</h3>
              </div>
            )}

          {searchCompleted && isError && (
            <div className={style.error}>
              <h3>
                An error occurred!
                <br />
                Please try your search later...
              </h3>
            </div>
          )}
        </section>
      </div>

      {typeof contentID !== 'undefined' &&
        typeof contentType !== 'undefined' && (
          <div
            className={
              style.contentScreen +
              ' ' +
              (Object.keys(contentSelected).length > 0 ? style.openDetails : '')
            }
          >
            <ContentDetails2
              onClose={handleClose}
              contentType={params.contentType || ''}
              contentID={params.contentID || ''}
            />
          </div>
        )}
    </main>
  );
};

export default UserSearch;
