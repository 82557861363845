import React, { useEffect, useRef, useState } from 'react';
import { ContentDetails } from '../../../types/local-models/ContentDetails.model';
import { MoviesApi } from '../../../services/content-apis/movies';
import BeginButton from '../../begin-button/begin-button';
import styles from '../ContentDetails2.module.css';

import { useNavigate } from 'react-router-dom';

import { FaPlayCircle } from 'react-icons/fa';
import { wishlistApi } from '../../../services/content-apis/wishlist';
import Loading from '../../Loading/Loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import arrowDown from '../../../assets/images/icons/arrow_down.png';
import arrowUp from '../../../assets/images/icons/arrow-up.png';

interface MovieDetails {
  movieID: string;
  movieName: string;
  genres: string;
  runtime: string;
  banner: string;
  overview: string;
  status: string;
  rating: string;
  languages: string;
  countries: string;
  cast: string;
  crew: string;
  publishedYear: string;
  streamUrl: string;
  mediaid: string;
  producers: string;
}

const MovieDetails: React.FC<ContentDetails> = ({
  contentID,
  setPlayerUrl,
  setPlaylist,
}) => {
  const [isSubscribed, setIsSubscribed] = React.useState<boolean | null>(null);
  const [activeTab, setActiveTab] = React.useState<string>('details');
  const [movie, setMovie] = React.useState<MovieDetails>();

  const [wishlistBtnTxt, setWishlistBtnTxt] = React.useState('Add to List');
  const [wishlistBtnIcon, setWishlistBtnIcon] = React.useState('icon-favorite');
  const [wishlistBtnLoading, setWishlistBtnLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState('225px');
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    MoviesApi.getMovie(contentID, 'movie').then((response: any) => {
      try {
        console.log('movie response for movie ', contentID, ': ', response);
        const content = response.streamLink[0];

        setIsSubscribed(response.isSubscribed);

        const banner = content.images.find(
          (img: any) => img.imageType === 'cover-hd'
        );

        let producers = '';
        if (typeof response.streamLink[0].producers !== 'undefined') {
          producers = response.streamLink[0].producers
            .map((producer: any) => producer.personName)
            .join(', ');
        }

        let casts = '';
        if (typeof response.streamLink[0].cast !== 'undefined') {
          casts = response.streamLink[0].cast
            .map((cast: any) => cast.personName)
            .join(', ');
        }

        setMovie({
          movieID: content.movieID,
          movieName: content.movieOriginalTitle,
          genres: response.genres,
          runtime: response.runtime,
          banner: typeof banner === 'undefined' ? '' : banner.imagePath,
          overview: content.movieOverview,
          publishedYear: response.publishedYear,
          streamUrl: content.movieStreamLink,
          rating: 'PG-13',
          languages: content.movieProductionLanguages.join(', '),
          countries: content.movieOriginCountry.join(', '),
          // cast: content?.cast?.join(', '),
          crew: content?.crew?.join(', '),
          mediaid: content.mediaid || '',
          status: content.movieStatus,
          producers: producers,
          cast: casts,
        });

        if (content.isAddedToMyList) {
          setWishlistBtnTxt('Remove from List');
          setWishlistBtnIcon('icon-favorite-filled');
        }
      } catch (error) {
        console.log('Error getting movie details', error);
      }
    });
  }, [contentID]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleOverview = () => {
    setIsOverviewExpanded(!isOverviewExpanded);
  };

  useEffect(() => {
    if (isExpanded && detailsRef.current) {
      setMaxHeight(`${detailsRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('300px');
    }
  }, [isExpanded]);

  return (
    <div className={styles.contentDetails}>
      <Loading show={loading} />
      <div className={styles.details}>
        {movie?.banner && (
          <LazyLoadImage
            className={styles.banner}
            effect="blur"
            src={movie?.banner}
            beforeLoad={() => {
              setLoading(true);
            }}
            onLoad={() => {
              setLoading(false);
            }}
          />
        )}

        <div className={styles.metadata}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <h1>{movie?.movieName}</h1>
              {/* <ul>
                <li>{movie?.publishedYear}</li>
                <li>{movie?.genres}</li>
                <li>{movie?.runtime}</li>
              </ul> */}
            </div>

            <div className={styles.btnGroup}>
              {isSubscribed && (
                <>
                  <BeginButton
                    preset="link"
                    type="button"
                    className="secondary"
                    to=""
                    datalabel="Play"
                    dataiconstart="icon-play"
                    onClick={() => {
                      setPlayerUrl(movie?.streamUrl || '');

                      if (typeof setPlaylist !== 'undefined') {
                        setPlaylist({
                          playlistIndex: 0,
                          PlaylistItems: [
                            {
                              title: movie?.movieName || '',
                              file: movie?.streamUrl || '',
                              image: movie?.banner || '',
                              isDRM: false,
                              mediaid: movie?.mediaid || '',
                              contentID: contentID,
                              videoContentID: contentID,
                              overview: '',
                              runtime: 0,
                            },
                          ],
                        });
                      }
                    }}
                  />

                  <BeginButton
                    className="white"
                    datalabel={wishlistBtnTxt}
                    type="button"
                    dataiconstart={wishlistBtnIcon}
                    squared={true}
                    loading={wishlistBtnLoading}
                    onClick={(event) => {
                      event.stopPropagation();

                      setWishlistBtnLoading(true);
                      if (wishlistBtnTxt === 'Remove from List') {
                        wishlistApi.removeFromWishList(
                          contentID,
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Add to List');
                              setWishlistBtnIcon('icon-favorite');
                            }
                          }
                        );
                      } else {
                        wishlistApi.addToWishList(
                          contentID,
                          'movie',
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Remove from List');
                              setWishlistBtnIcon('icon-favorite-filled');
                            }
                          }
                        );
                      }
                    }}
                  />
                </>
              )}
              {isSubscribed === false && (
                <BeginButton
                  className="primary"
                  datalabel="Subscribe Now"
                  to=""
                  type="button"
                  dataiconstart="icon-locked"
                  squared={true}
                  tall={true}
                  onClick={(event) => {
                    event.stopPropagation();
                    localStorage.setItem(
                      'lastLocation',
                      `/movies/${contentID}`
                    );
                    navigate(
                      `/new-available-subscriptions/movies/${contentID}`
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.overview}>
        <div className={styles.wrapper}>
          <div className={`${styles.container} ${styles.movies}`}>
            <div className={styles.status}>
              <div className={styles.overviewDetails}>
                <ul>
                  <li>{movie?.publishedYear}</li>

                  {movie?.rating && (
                    <li>
                      <div className={styles.rating}>{movie?.rating}</div>
                    </li>
                  )}

                  <li>{movie?.runtime}</li>
                </ul>
              </div>
              <p>{movie?.overview}</p>
            </div>
            <div className={styles.tabsContainer}>
              <div className={styles.episodeTabsDiv}>
                {/* <a
                  className={`${styles.tab} ${
                    activeTab === 'moreLikeThis' ? styles.active : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('moreLikeThis');
                  }}
                >
                  More Like This
                </a> */}
                <a
                  className={`${styles.tab} ${
                    activeTab === 'details' ? styles.active : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('details');
                  }}
                >
                  Details
                </a>
              </div>
            </div>
            <div className={styles.additionalDetails}>
              {activeTab === 'moreLikeThis' && (
                <div className={styles.moreLikeThis}>
                  More Like This content
                </div>
              )}

              {activeTab === 'details' && (
                <div className={styles.details}>
                  {/* <div className={styles.overviewDetails}>
                    <ul>
                      <li>{movie?.publishedYear}</li>

                      {movie?.rating && (
                        <li>
                          <div className={styles.rating}>{movie?.rating}</div>
                        </li>
                      )}

                      <li>{movie?.runtime}</li>
                    </ul>
                  </div> */}

                  <div className={styles.bulletList}>
                    {movie?.genres && (
                      <div>
                        <span className={styles.bulletText}>
                          {movie?.genres}
                        </span>
                      </div>
                    )}
                    {movie?.languages && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {movie?.languages}
                        </span>
                      </div>
                    )}
                    {movie?.countries && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {movie?.countries}
                        </span>
                      </div>
                    )}
                    {/* {movie?.runtime && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {movie?.runtime}
                        </span>
                      </div>
                    )} */}
                  </div>
                  <div
                    className={`${styles.castCrewContainer} ${
                      isExpanded ? styles.expanded : ''
                    }`}
                    style={{ maxHeight }}
                    ref={detailsRef}
                  >
                    <div className={styles.column}>
                      <h2>Cast</h2>
                      <ul className={styles.columnContainer}>
                        {movie?.cast &&
                          movie.cast
                            .split(',')
                            .map((actor, index) => (
                              <li key={index}>{actor.trim()}</li>
                            ))}
                      </ul>
                    </div>
                    <div className={styles.column}>
                      <h2>Crew</h2>
                      <ul className={styles.columnContainer}>
                        {movie?.producers &&
                          movie.producers
                            .split(',')
                            .map((crewMember, index) => (
                              <li key={index}>{crewMember.trim()}</li>
                            ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.toggleButtonContainer}>
                    <button
                      onClick={toggleExpand}
                      className={styles.toggleButton}
                    >
                      <img
                        src={isExpanded ? arrowUp : arrowDown}
                        alt="Toggle"
                        className={styles.toggleArrow}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieDetails;
