import React from 'react';
import JWPlayer from '@jwplayer/jwplayer-react';
import {
  PlayerContainerModel,
  PlaylistItemModel,
} from '../../types/local-models/Playlist.model';

class PlayerComponent extends React.Component<PlayerContainerModel> {
  player: any;
  contentID: string;
  playlist: PlaylistItemModel[];
  playlistIndex: number;
  heartbeatInterval: number;

  seekOffset = 0;

  heartbeatCounter = 0;

  constructor(props: PlayerContainerModel) {
    super(props);
    this.player = {};
    this.contentID = props.contentID || '';
    this.playlist = props.playlist;
    this.onPlay = this.onPlay.bind(this);

    this.playerMountedCallback = this.playerMountedCallback.bind(this);
    this.playerUnmountingCallback = this.playerUnmountingCallback.bind(this);

    if (typeof props.playlistIndex !== 'undefined') {
      this.playlistIndex = props.playlistIndex;
    } else {
      this.playlistIndex = 0;
    }

    this.heartbeatInterval = 0;
  }

  playerMountedCallback(player: any, id: any) {
    this.player = player;

    console.log('this.playlistIndex', this.playlist);
    if (
      typeof this.playlistIndex !== 'undefined' &&
      typeof this.playlist[this.playlistIndex].videoContentID === 'string'
    ) {
      const data = localStorage.getItem(
        this.playlist[this.playlistIndex].videoContentID || ''
      );

      if (typeof data !== 'undefined' && data !== null) {
        console.log('data', data);
        const parsedData = JSON.parse(data || '');

        if (parsedData.progress > 0 && parsedData.progress < 99) {
          this.seekOffset = parsedData.progress;

          this.player.player.seek(parsedData.currentTime);
        }
      }
    }
  }

  // Nulls registered players as they unmount
  playerUnmountingCallback(id: any) {
    this.player = null;
    window.clearInterval(this.heartbeatInterval);
  }

  onPlay(event: any) {
    console.log('contentID', this.contentID);
    localStorage.setItem(this.contentID, this.player.player.getPlaylistIndex());

    const jwTitle = document.querySelector('.jw-title');

    if (jwTitle) {
      jwTitle.classList.add('jw-title--visible');

      window.setTimeout(() => {
        jwTitle.classList.remove('jw-title--visible');
      }, 5500);
    }

    this.heartbeatInterval = window.setInterval(() => {
      this.heartbeat();
    }, 10000);
  }

  heartbeat() {
    if (this.player) {
      if (this.player.player.getState() === 'playing') {
        const currentTime = this.player.player.getPosition();
        const duration = this.player.player.getDuration();
        const progress = (currentTime / duration) * 100;

        if (progress > 0 && progress < 100) {
          const index = this.player.player.getPlaylistIndex();
          const item = this.playlist[index];

          if (typeof item.videoContentID !== 'undefined') {
            const data = {
              videoContentID: item.videoContentID,
              progress,
              duration,
              currentTime,
            };

            console.log('heartbeat', JSON.stringify(data));

            localStorage.setItem(item.videoContentID, JSON.stringify(data));
          }
        }
      }
    }
  }

  render() {
    // Re-usable defaults to use between multiple players.
    const seventyPercentOfWindowWidth = (70 / 100) * window.innerWidth;
    const configDefaults =
      typeof this.props.vodContent !== 'undefined'
        ? {
            width: window.innerWidth,
            height: window.innerHeight,
            allowFullscreen: true,
            displaytitle: true,
          }
        : {
            width: seventyPercentOfWindowWidth,
            height: (9 / 16) * seventyPercentOfWindowWidth,
            allowFullscreen: false,
            displaytitle: true,
          };

    return (
      <div className="players-container">
        {this.props.playerEnabled && (
          <JWPlayer
            id="my-player"
            didMountCallback={this.playerMountedCallback}
            willUnmountCallback={this.playerUnmountingCallback}
            config={configDefaults}
            autostart="true"
            displayMode="overlay"
            liveTimeout={0}
            playlist={this.playlist}
            playlistIndex={this.playlistIndex}
            onPlay={this.onPlay}
            library="https://cdn.jwplayer.com/libraries/UKjIJKYL.js"
          />
        )}
      </div>
    );
  }
}
export default PlayerComponent;
