import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import style from '../auth-child.module.css';
import ProgressDots from '../../../components/progress-dots/progress-dots';

import OtpInput from 'react-otp-input';
import BeginButton from '../../../components/begin-button/begin-button';
import { useNavigate } from 'react-router-dom';

import { regex, errorMessages } from '../auth-child-constants';
import BeginInput from '../../../components/form-input-fields/begin-input';
import { RootState } from '../../../store';
import { resetPassword } from '../../../services/Cognito';
import { BeginToastModel } from '../../../types/local-models/begin-toast.component.model';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const otpLoadedHandler = () => {
    dispatch(landingActions.childLoaded());
  };

  const navigate = useNavigate();

  const otpInfo = useSelector(
    (state: RootState) => state.landing.resetPasswordOTP
  );

  useEffect(() => {
    if (otpInfo.account === '') {
      navigate('/account/forgot-password');
    }
    ReactGA.event({
      category: 'Reset Password',
      action: 'ResetPassword_View',
      nonInteraction: false,
    });
    ReactPixel.track('ResetPassword_View');
    window.setTimeout(() => {
      otpLoadedHandler();
    }, 1500);
    dispatch(landingActions.hideLoader());
    dispatch(landingActions.hideToast());
  }, []);

  const [formValid, setFormValid] = useState(false);

  const [otp, setOTP] = useState('');

  const [formValues, setFormValues] = useState({
    accountId: otpInfo.account,
    password: '',
    confirmPassword: '',
  });

  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: '',
    otp: '',
  });

  const checkFormValidity = (setError = false) => {
    if (
      formValues.accountId.match(regex.accountId) != null &&
      formValues.password.match(regex.password) != null &&
      formValues.password == formValues.confirmPassword
    ) {
      for (const formEntry in formErrors) {
        setFormErrors((prevValues) => ({
          ...prevValues,
          [formEntry]: '',
        }));
      }

      setFormValid(true);
    } else {
      setFormValid(false);

      if (setError) {
        for (const formEntry in regex) {
          if (
            typeof formValues[formEntry as keyof typeof formValues] !=
            'undefined'
          ) {
            if (
              formValues[formEntry as keyof typeof formValues].match(
                regex[formEntry as keyof typeof regex]
              ) == null
            ) {
              setFormErrors((prevValues) => ({
                ...prevValues,
                [formEntry]:
                  errorMessages[formEntry as keyof typeof errorMessages],
              }));
            } else {
              setFormErrors((prevValues) => ({
                ...prevValues,
                [formEntry]: '',
              }));
            }
          }
        }

        if (formValues.password != formValues.confirmPassword) {
          setFormErrors((prevValues) => ({
            ...prevValues,
            ['confirmPassword']: errorMessages.confirmPassword,
          }));
        } else {
          setFormErrors((prevValues) => ({
            ...prevValues,
            ['confirmPassword']: '',
          }));
        }
      }
    }
  };

  const handleInputChange = (identifier: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));

    checkFormValidity();
  };

  const handleInputBlur = () => {
    checkFormValidity(true);
  };

  const otpSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    ReactGA.event({
      category: 'Reset Password',
      action: 'RPW_Submit_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('RPW_Submit_Initiate');
    event.preventDefault();

    dispatch(landingActions.hideToast());

    if (formValid) {
      dispatch(landingActions.showLoader());
      resetPassword(
        formValues.accountId,
        formValues.password,
        otp,
        (err, result) => {
          if (err) {
            const toastError: BeginToastModel = {
              type: 'error',
              shown: true,
              text: err.message,
              dismissduration: 5000,
            };
            dispatch(landingActions.showToast(toastError));
            dispatch(landingActions.hideLoader());
            return;
          } else {
            const toastSuccess: BeginToastModel = {
              type: 'success',
              shown: true,
              text: 'Your password was successfully reset. Please sign in to continue!',
              dismissduration: 5000,
            };
            dispatch(landingActions.showToast(toastSuccess));
            dispatch(landingActions.hideLoader());

            window.setTimeout(() => {
              navigate('/account/');
            }, 2500);
          }
        }
      );
      ReactGA.event({
        category: 'Reset Password',
        action: 'RPW_Submit_Success',
        nonInteraction: false,
      });
      ReactPixel.track('RPW_Submit_Success');
    } else {
      ReactGA.event({
        category: 'Reset Password',
        action: 'RPW_Submit_Failed',
        nonInteraction: false,
      });
      ReactPixel.track('RPW_Submit_Failed');
      const toastError: BeginToastModel = {
        type: 'error',
        shown: true,
        text: 'Please fix the errors in the form before submitting.',
        dismissduration: 2500,
      };
      dispatch(landingActions.showToast(toastError));
    }
  };

  return (
    <div className={style.authContainer}>
      <form onSubmit={otpSubmit} className={style.form}>
        <input type="hidden" readOnly value={formValues.accountId} />
        <div className={style.authContent}>
          <h1 className="title">Reset Password</h1>

          <div className={style.group}>
            <ProgressDots dots={2} activeIndex={1} />
            <div className="preHeading">OTP</div>
            <h1>OTP Verification for Password Reset</h1>
            <div className={style.titleLabel}>
              Please enter the following information
            </div>
            <div className={style.separator}></div>
          </div>

          <div className={style.group}>
            <BeginInput
              type="password"
              placeholder="Enter Password"
              name="password"
              id="password"
              required={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('password', event?.target?.value)
              }
              onBlur={() => handleInputBlur()}
              value={formValues.password}
              error_message={formErrors.password}
            />

            <BeginInput
              type="password"
              placeholder="Re-Enter Password"
              name="confirm-password"
              id="confirm-password"
              required={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('confirmPassword', event?.target?.value)
              }
              onBlur={() => handleInputBlur()}
              value={formValues.confirmPassword}
              error_message={formErrors.confirmPassword}
            />

            <div className={style.optText}>
              Please enter the 6 digit OTP&nbsp;
              {otpInfo.serverResponse.CodeDeliveryDetails.AttributeName}ed
              &nbsp;to&nbsp;
              {otpInfo.serverResponse.CodeDeliveryDetails.Destination}
            </div>

            <div className={style.forgotPwdOTP}>
              <OtpInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                inputStyle={style.resetPwdOtpInput}
                inputType="number"
                containerStyle={style.otpContainer}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          </div>

          <div className={style.group}>
            <BeginButton
              type="submit"
              datalabel="Submit"
              className="primary"
              to=""
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
