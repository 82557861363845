import api, { Headers } from '../config/api';
import { getPayLoad } from '../config/payload';

import { isSubscribed } from './helpers';

function convertToCardData(res: any) {
  const cardData = {
    genres: res[0].genres.map((genre: any) => genre.genreName).join(' / '),
    runtime: res[0].seriesRuntime + ' minutes / episode',
    publishedYear:
      res[0].seasons.length +
      (res[0].seasons.length > 1 ? ' Seasons' : ' Season'),
    tagline: res[0].seriesOverview,
    isSubscribed: isSubscribed(res[0].subscriptions),
    streamLink: res,
  };
  return cardData;
}

const url = 'content/series';
const episodesUrl = 'content/episodes';

export const tvShowsApi = {
  getAll: async (genre: string) => {
    const payload = getPayLoad({
      screen: 'view all',
      seriesType: 'tvseries',
      genre: genre,
      profileID: localStorage.getItem('profileID') || '',
    });

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  getTVShow: async (id: string, type: string) => {
    const payload = {
      operation: 'read',
      payload: {
        seriesType: 'tvseries',
        seriesID: id,
        accountID: localStorage.getItem('accountID'),
        profileID: localStorage.getItem('profileID') || '',
        select: [],
        filter: {},
      },
    };

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });
      return convertToCardData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  getEpisodesBySeason: async (seasonID: string) => {
    const payload = {
      operation: 'list',
      payload: {
        seasonID: seasonID,
        accountID: localStorage.getItem('accountID'),
      },
    };

    try {
      const res = await api.post(episodesUrl, payload, {
        headers: Headers,
      });

      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};
