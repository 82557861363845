import React from 'react';
import MyAccount from './partials/my-account';
import SettingsDialog from './partials/settings-dialog';
import Help from './partials/help';
import MySubscriptions from './partials/my-subscriptions';

const UserSettings = () => {
  return (
    <SettingsDialog>
      <MyAccount />
      <MySubscriptions />
      <Help />
    </SettingsDialog>
  );
};

export default UserSettings;
