import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../services/Cognito';

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    logout();

    // navigate('/');
    window.location.href = '/';
  }, []);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
};

export default Logout;
