import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { getFirebasePermissions } from './firebase';
import Loading from './components/Loading/Loading';
import { DevicesApi } from './services/customer-devices';
import ReactGA from 'react-ga4';
import { GID } from './utils/google-containers';
import ReactPixel from 'react-facebook-pixel';
import router from './utils/router';
import { detectOS } from './utils/detectOS';
import './assets/fonts/fontello/css/fontello.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.initialize(GID);
    const pixelID = '3339150763054070';
    ReactPixel.init(pixelID);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (!isIOS) {
      getFirebasePermissions((token) => {
        DevicesApi.addDevice(token);
      });
    }

    const detectedOS = detectOS();
    console.log(`Detected OS: ${detectedOS}`);

    // Check if we are already on the /download-app page
    if (window.location.pathname !== '/download-app') {
      if (detectedOS === 'Android' || detectedOS === 'HarmonyOS') {
        window.location.href = '/download-app';
        return;
      }
    }

    setIsLoading(false); // Set loading state to false if OS is not detected
  }, []);

  if (isLoading) {
    return <Loading show={true} />;
  }

  return (
    <div>
      <RouterProvider router={router()} />
      <Loading show={false} />
    </div>
  );
};

export default App;
