export const PaymentMethodsList = [
  {
    label: 'Card',
    value: 'card',
    icon: 'MasterCard.png',
    minAmount: 300,
  },
  {
    label: 'EasyPaisa',
    value: 'easypaisa',
    icon: 'EasyPaisa.png',
    minAmount: 0,
  },
  {
    label: 'JazzCash',
    value: 'jazzcash',
    icon: 'JazzCash.png',
    minAmount: 0,
  },
];
