import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import style from '../auth-child.module.css';

import ProgressDots from '../../../components/progress-dots/progress-dots';
import { useNavigate, useParams } from 'react-router-dom';
import BeginButton from '../../../components/begin-button/begin-button';
import { subscriptionDetails } from '../../../types/remote-dtos/subscriptions.dto';

import { FaChevronLeft } from 'react-icons/fa';

export const SubscriptionFineTuning: FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();

  const [subscriptionsList, setSubscriptionsList] = useState<
    subscriptionDetails[]
  >([]);

  const [selectedSubscriptions, setSelectedSubscriptions] = useState();

  const [selectedBillingCycle, setSelectedBillingCycle] = useState('monthly');

  const [costs, setCosts] = useState<any>();

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);

    dispatch(landingActions.hideToast());
    dispatch(landingActions.showLoader());

    const subscriptionsListLocal = localStorage.getItem('subscriptionList');
    const selectedSubscriptionsLocal = localStorage.getItem(
      'selectedSubscriptions'
    );
    const subscriptionDurations = localStorage.getItem('subscriptionDurations');

    if (
      subscriptionsListLocal &&
      selectedSubscriptionsLocal &&
      subscriptionDurations
    ) {
      setSubscriptionsList(JSON.parse(subscriptionsListLocal));
      setSelectedSubscriptions(JSON.parse(selectedSubscriptionsLocal));
      setCosts(JSON.parse(subscriptionDurations));

      dispatch(landingActions.hideLoader());
    } else {
      navigate('/account/available-subscriptions');
    }
  }, [params]);

  const getCurrentDate = () => {
    const separator = ' ';
    const newDate = new Date();
    const date = newDate.getDate();
    const month = newDate.toLocaleString('default', { month: 'short' });
    const year = newDate.getFullYear();

    const ordinal = (d: number) => {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    return `${date}${ordinal(date)}${separator}${month}${separator}${year}`;
  };

  const savings = () => {
    const fullPrice =
      costs.find((entry: any) => entry.type === 'monthly').price * 12;
    return Math.trunc(
      ((fullPrice - costs.find((entry: any) => entry.type === 'yearly').price) /
        fullPrice) *
        100
    );
  };

  const billingCycle = (selectedCycle: string) => {
    setSelectedBillingCycle(selectedCycle);
  };

  const subscriptionsSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    localStorage.setItem('selectedBillingCycle', selectedBillingCycle);
    localStorage.setItem(
      'selectedBillingCycleCost',
      costs
        .find((entry: any) => entry.type == selectedBillingCycle)
        .price.toString()
    );

    navigate('/account/payment-methods');
  };

  return (
    <div className={style.authContainer}>
      <form
        onSubmit={subscriptionsSubmit}
        className={style.form}
      >
        <div className={style.authContent}>
          <h1 className="title">
            <FaChevronLeft
              className={style.backBtn}
              onClick={() => navigate(-1)}
            />
            Subscription
          </h1>

          <div className={style.group}>
            <ProgressDots
              dots={3}
              activeIndex={1}
            />
            <div className="preHeading">Checkout</div>
            <h1>Your subscription breakdown</h1>
            <div className={style.titleLabel}>
              Breakdown of your subscription(s) & billing type
            </div>
            <div className={style.separator}></div>
          </div>

          <h3>{selectedSubscriptions}</h3>

          {/* <div className={style.table}>
            <table>
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Monthly</td>
                  <td>Annually</td>
                </tr>
              </thead>

              <tbody>
                {typeof costs != 'undefined' &&
                  costs.map((entry: any) => {
                    return (
                      selectedSubscriptions[
                        entry.subscriptionTitle as keyof typeof selectedSubscriptions
                      ] && (
                        <tr key={entry.subscriptionID}>
                          <td className={style.subscriptionTitle}>
                            {entry.subscriptionTitle}
                          </td>
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
          </div> */}

          {typeof costs != 'undefined' && (
            <>
              <div className={style.group}>
                {costs.map((entry: any) => {
                  return (
                    <label
                      key={Math.random()}
                      className={`${style.billingCycleLabel} ${
                        selectedBillingCycle == entry.type
                          ? style.selectedBillingCycleLabel
                          : ''
                      }`}
                    >
                      <input
                        type="radio"
                        name="billingCycle"
                        onChange={() => {
                          billingCycle(entry.type);
                        }}
                        checked={selectedBillingCycle == entry.type}
                      />
                      {entry.type} billing
                    </label>
                  );
                })}
              </div>

              {typeof selectedBillingCycle != 'undefined' && (
                <>
                  <div className={style.buttonGroup}>
                    <div className={style.billingDetails}>
                      <div className="preHeading">
                        Your Billing Cycle Starts From
                        <h1>{getCurrentDate()}</h1>
                      </div>
                    </div>

                    <div className={style.billingDetails}>
                      <div className="preHeading">
                        Billing Type
                        <h1>{selectedBillingCycle}</h1>
                      </div>
                    </div>

                    <div className={style.billingDetails}>
                      <div className="preHeading">
                        Your Total
                        <h1>
                          {
                            costs.find(
                              (entry: any) => entry.type == selectedBillingCycle
                            ).price
                          }
                        </h1>
                        {selectedBillingCycle == 'yearly' && (
                          <small className={style.savings}>
                            You save {savings()}%
                          </small>
                        )}
                      </div>
                    </div>

                    <BeginButton
                      type="submit"
                      className="primary"
                      to=""
                      datalabel="Next"
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};
