import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import style from '../auth-child.module.css';
import ProgressDots from '../../../components/progress-dots/progress-dots';

import OtpInput from 'react-otp-input';
import BeginButton from '../../../components/begin-button/begin-button';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { loginUser, verifyUser } from '../../../services/Cognito';
import { BeginToastModel } from '../../../types/local-models/begin-toast.component.model';
import { CreateAccountOTPModel } from '../../../types/local-models/create-account-otp.component.model';
import { PostAddCustomerDTO } from '../../../types/remote-dtos/customer-details.dtos';
import {
  addCustomerAPI,
  getCustomerAPI,
} from '../../../services/customer-details';

const OTP: FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const createAccountOTP: CreateAccountOTPModel = useSelector(
    (state: RootState) => state.landing.createAccountOTP
  );

  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    let live = false;
    if (window.location.pathname.indexOf('live') > -1) {
      setIsLive(true);
      live = true;
    }

    if (createAccountOTP.accountID === '') {
      if (live) {
        navigate('/live/login');
      } else {
        navigate('/account/login');
      }
    }

    dispatch(landingActions.childLoading());
    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 500);

    dispatch(landingActions.hideLoader());
    dispatch(landingActions.hideToast());
  }, []);

  const [otp, setOtp] = useState('');

  const otpSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(landingActions.showLoader());
    dispatch(landingActions.hideToast());

    await verifyUser(createAccountOTP.accountID, otp, (err, result) => {
      if (err) {
        const toastError: BeginToastModel = {
          type: 'error',
          shown: true,
          text: err.message,
          dismissduration: 5000,
        };
        dispatch(landingActions.showToast(toastError));
        dispatch(landingActions.hideLoader());

        return;
      } else {
        console.log('OTP verified successfully!', result);
        const addCustomer: PostAddCustomerDTO = {
          operation: 'update',
          payload: {
            accountID: createAccountOTP.accountID,
            attributes: {
              accountCognitoResponse: {
                username: createAccountOTP.accountID,
                authToken: '',
                refreshToken: '',
                emailVerified: true,
                phoneVerified: false,
                userSub: createAccountOTP.UserSub,
              },
            },
          },
        };

        console.log('addCustomer: ', addCustomer);

        try {
          addCustomerAPI(addCustomer, (value) => {
            if (value) {
              let text =
                'Your account has been verified. Please sign in to continue!';
              if (isLive) {
                text =
                  "Your account has been verified. Please wait while you're signed in!";
              }
              const toastSuccess: BeginToastModel = {
                type: 'success',
                shown: true,
                text: text,
                dismissduration: 5000,
              };
              dispatch(landingActions.showToast(toastSuccess));
              dispatch(landingActions.hideLoader());

              // if (!isLive) {
              //   window.setTimeout(() => {
              //     navigate('/account/');
              //   }, 2500);
              // } else {
              window.setTimeout(() => {
                async function signin() {
                  await loginUser(
                    createAccountOTP.accountID,
                    createAccountOTP.password,
                    (errLogin, resultLogin) => {
                      if (errLogin) {
                        const toastError: BeginToastModel = {
                          type: 'error',
                          shown: true,
                          text: errLogin.message,
                          dismissduration: 5000,
                        };
                        dispatch(landingActions.showToast(toastError));
                        dispatch(landingActions.hideLoader());
                        return;
                      } else {
                        getCustomerAPI(
                          createAccountOTP.accountID,
                          (response) => {
                            dispatch(landingActions.hideLoader());
                            if (response) {
                              if (isLive) {
                                // navigate('/live/available-subscriptions');
                                window.location.href =
                                  '/live/available-subscriptions';
                              } else {
                                window.location.href = '/profile-load';
                              }
                            }
                          }
                        );
                      }
                    }
                  );
                }

                signin();
              }, 500);
              // }
            }
          });
        } catch (error: any) {
          const toastSuccess: BeginToastModel = {
            type: 'error',
            shown: true,
            text:
              'Your account creation process failed ' + JSON.stringify(error),
            dismissduration: 5000,
          };
          dispatch(landingActions.showToast(toastSuccess));
          dispatch(landingActions.hideLoader());
        }
      }
    });
  };

  return (
    <div className={style.authContainer}>
      <form
        onSubmit={otpSubmit}
        className={style.form}
      >
        <div className={style.authContent}>
          <h1 className="title">Create Account</h1>

          <div className={style.group}>
            <ProgressDots
              dots={2}
              activeIndex={1}
            />
            <h1>OTP Verification</h1>
            <div className={style.titleLabel}>
              Please enter the following information
            </div>

            <div
              className={style.titleLabel}
              style={{ fontStyle: 'italic', fontSize: 'small' }}
            >
              The OTP was sent to your email address
            </div>
            <div className={style.separator}></div>
          </div>

          <div className={style.group}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={style.otpContainer}
              inputStyle={style.resetPwdOtpInput}
              inputType="number"
              renderSeparator={<span>&nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>

          <div className={style.group}>
            <BeginButton
              type="submit"
              datalabel="Submit"
              className="primary"
              to=""
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default OTP;
