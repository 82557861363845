export const SubscriptionImages = {
  International: [
    '/subscriptions/international/AEW.png',
    '/subscriptions/international/DC Animated Movies.png',
    '/subscriptions/international/FIFA.png',
    '/subscriptions/international/HBO Shows.png',
    '/subscriptions/international/Movies.png',
    '/subscriptions/international/Senna.png',
    '/subscriptions/international/TV Shows.png',
    '/subscriptions/international/Warner Bros Movies.png',
  ],
  Basic: [
    '/subscriptions/basic/aangan-terha.png',
    '/subscriptions/basic/alif-n-noon.png',
    '/subscriptions/basic/andhera-ujaala.png',
    '/subscriptions/basic/ankahi.png',
    '/subscriptions/basic/anwar-maqsood.png',
    '/subscriptions/basic/enek-wala-jin.png',
    '/subscriptions/basic/neelam-ghhar.png',
    '/subscriptions/basic/ptv.png',
  ],
};
