import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import BeginButton from '../../components/begin-button/begin-button';
import './NewPayment.css';
import bSecure from '../../assets/images/bSecure.png';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { addCustomerSubscriptions } from '../../services/subscriptions';
import debitSelected from '../../assets/images/subscriptions/debitSelected.png';
import wallet from '../../assets/images/subscriptions/wallet.png';
import walletSelected from '../../assets/images/subscriptions/walletSelected.png';
import debit from '../../assets/images/subscriptions/debit.png';

const NewPayment: React.FC = () => {
  const location = useLocation();
  const { selectedSubscription, subscriptionList } = location.state || {};
  console.log('state', location.state);

  const [loader, setLoader] = useState(false);

  const formatDate = (date: Date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const getOrdinalSuffix = (n: number) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const paymentMethods = subscriptionList?.subscriptionPaymentMethods || [];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(paymentMethods[0] || null);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const addUserSub = () => {
    setLoader(true);
    localStorage.setItem(
      'selectedBillingCycle',
      selectedSubscription.selectedBillingCycle
    );
    localStorage.setItem('selectedPaymentMethod', selectedPaymentMethod || '');
    localStorage.setItem(
      'selectedSubscriptionID',
      selectedSubscription.selectedSubscriptionID
    );

    console.log(
      'data',
      selectedPaymentMethod,
      selectedSubscription.selectedBillingCycle,
      selectedSubscription.selectedPrice
    );

    addCustomerSubscriptions((response) => {
      ReactGA.event({
        category: 'Settings-Subscriptions',
        label: response,
        action: 'SS_PackageSelect_Success',
        nonInteraction: false,
      });
      ReactPixel.track('SS_PackageSelect_Success');

      console.log('res', response);
      window.location.href =
        response +
        '&redirect=' +
        window.location.origin +
        localStorage.getItem('lastLocation');
    });
  };

  if (!selectedSubscription || !subscriptionList) {
    return <div>Loading...</div>;
  }

  const subscriptionPricing =
    selectedSubscription.selectedSubscriptions?.subscriptionPricing || [];

  return (
    <div className="subscription-bg">
      {selectedSubscription && (
        <div className="overlay">
          <h1 className="titleTop">Choose Your Payment Method</h1>
          <div className="payment-popup">
            <div className="payment-methods">
              {paymentMethods.map((method: string) => (
                <div
                  key={method}
                  className={`payment-method ${
                    selectedPaymentMethod === method ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedPaymentMethod(method)}
                >
                  {method === 'card' && (
                    <span
                      className={`payment-content ${
                        selectedPaymentMethod === 'card' ? 'selected-font' : ''
                      }`}
                    >
                      <img
                        src={
                          selectedPaymentMethod === 'card'
                            ? debitSelected
                            : debit
                        }
                        alt="Debit or Credit Card"
                      />
                      Debit or Credit Card
                    </span>
                  )}
                  {method === 'wallet' && (
                    <span
                      className={`payment-content ${
                        selectedPaymentMethod === 'wallet'
                          ? 'selected-font'
                          : ''
                      }`}
                    >
                      <img
                        src={
                          selectedPaymentMethod === 'wallet'
                            ? walletSelected
                            : wallet
                        }
                        alt="Wallet"
                      />
                      Wallet
                    </span>
                  )}
                </div>
              ))}
            </div>
            <div className="bsecure-tag">
              <div>
                <img src={bSecure} alt="" />
              </div>
              <div>
                <p>Protected by BSecure</p>
              </div>
            </div>
            <div className="payment-details">
              <p>YOUR BILLING STARTS FROM</p>
              <h2>{formatDate(new Date())}</h2>
              <p>
                BILLING TYPE
                <h2>
                  {capitalizeFirstLetter(
                    selectedSubscription.selectedBillingCycle
                  )}{' '}
                </h2>
              </p>
              <p>
                YOUR TOTAL
                <h2>{selectedSubscription.selectedPrice} PKR </h2>
              </p>
              {selectedSubscription?.selectedDiscount !== 0 &&
                selectedSubscription?.selectedDiscount !== undefined && (
                  <p className="save-price">
                    You save{' '}
                    {selectedSubscription?.originalPrice -
                      selectedSubscription.selectedPrice}{' '}
                    PKR
                  </p>
                )}
              {selectedSubscription.selectedTrialDays !== 0 && (
                <p className="free-trial">
                  {selectedSubscription.selectedTrialDays} Day Free Trial
                </p>
              )}
              {selectedSubscription.selectedTrialDays !== 0 && (
                <p>
                  Note: Your account will be billed after the{' '}
                  {selectedSubscription.selectedTrialDays}-day trial period has
                  finished.
                </p>
              )}
            </div>
          </div>
          <div className="btnCustom">
            <BeginButton
              className="primary"
              datalabel="Next"
              to=""
              type="button"
              disabled={!selectedPaymentMethod}
              onClick={(event) => {
                event.stopPropagation();
                if (selectedPaymentMethod) {
                  addUserSub();
                }
              }}
            />
          </div>
        </div>
      )}

      {loader && <div className="overlayLoader"></div>}
    </div>
  );
};

export default NewPayment;
