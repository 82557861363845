import React, { FC } from 'react';
import styles from './begin-toast.module.css';
import { BeginToastModel } from '../../types/local-models/begin-toast.component.model';

import {
  FaExclamationCircle,
  FaCheckCircle,
  FaInfoCircle,
  FaTimesCircle,
  FaTimes,
} from 'react-icons/fa';

import { landingActions } from '../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';

const BeginToast = (props: BeginToastModel) => {
  const dispatch = useDispatch();
  const icons = {
    error: <FaTimesCircle />,
    info: <FaInfoCircle />,
    success: <FaCheckCircle />,
    warn: <FaExclamationCircle />,
  };
  return (
    <div
      className={
        styles.toast +
        ' ' +
        styles[props.type] +
        ' ' +
        (props.shown ? styles.shown : '')
      }
      onClick={() => {
        dispatch(landingActions.hideToast());
      }}
    >
      <div className={styles.icon}>{icons[props.type]}</div>
      <div className="message">
        <h1 className={styles.heading}>{props.type}</h1>
        {props.text}
      </div>
    </div>
  );
};

export default BeginToast;
