import api from '../config/api';

const url = 'masterdata/genres';
const genreurl = 'search/filters';

export const GenresApi = {
  getAll: async () => {
    const payload = {
      operation: 'list',
      masterdata: 'genres',
      payload: {
        select: [
          'genreID',
          'genreName',
          // "genreStatus"
        ],
        filter: {
          // "genreStatus": "active"
        },
      },
    };

    try {
      const res = await api.post(url, payload);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};
export const MovieGenres = {
  getAll: async () => {
    const payload = {
      operation: 'filters',
      payload: {
        contentType: 'movies', // movies, tvseries, sports
        select: [
          'genreID',
          'genreName',
          // "genreStatus"
        ],
        filter: {
          // "genreStatus": "active"
        },
      },
    };

    try {
      const res = await api.post(genreurl, payload);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export const TVseriesGenres = {
  getAll: async () => {
    const payload = {
      operation: 'filters',
      payload: {
        contentType: 'tvseries', // movies, tvseries, sports
        select: [
          'genreID',
          'genreName',
          // "genreStatus"
        ],
        filter: {
          // "genreStatus": "active"
        },
      },
    };

    try {
      const res = await api.post(genreurl, payload);
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};
