import {
  PostAddCustomerDTO,
  PostResponseCustomerDTO,
} from '../types/remote-dtos/customer-details.dtos';
import { appEndpoints } from '../api-endpoints';
import axios from 'axios';

import { Headers } from './config/api';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
export async function isLoggedIn(): Promise<boolean> {
  const cognitoUser = localStorage.getItem('cognito_user');
  const beginUser = localStorage.getItem('begin_user');

  if (cognitoUser !== null && beginUser !== null) {
    return true;
  } else {
    return false;
  }
}

export async function addCustomerAPI(
  userDetails: PostAddCustomerDTO,

  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'accounts';
  const data = JSON.stringify(userDetails);

  ReactGA.event({
    category: 'Profiles',
    label: userDetails.payload.accountID,
    action: 'Prof_Add_Initiate',
    nonInteraction: false,
  });
  ReactPixel.track('Prof_Add_Initiate');
  try {
    if (userDetails.operation !== 'update') {
      axios
        .post(url, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          ReactGA.event({
            category: 'Profiles',
            label: userDetails.payload.accountID,
            action: 'Prof_Add_Success',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_Add_Success');
          // console.log('response: ', response);
          callback(response);
        })
        .catch(function (error) {
          ReactGA.event({
            category: 'Profiles',
            label: error.response.status,
            action: 'Prof_Add_Failed',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_Add_Failed');
          callback(false);
        });
    } else {
      axios
        .patch(url, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          ReactGA.event({
            category: 'Profiles',
            label: userDetails.payload.accountID,
            action: 'Prof_Add_Success',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_Add_Success');
          // console.log('response: ', response);
          callback(response);
        })
        .catch(function (error) {
          ReactGA.event({
            category: 'Profiles',
            label: error.response.status,
            action: 'Prof_Add_Failed',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_Add_Failed');
          callback(false);
        });
    }
  } catch (e) {
    callback(false);
  }
}

export async function getCustomerAPI(
  email: string,
  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'accounts';
  const data = JSON.stringify({
    operation: 'read',
    payload: {
      accountID: email,
    },
  });

  try {
    axios.post(url, data, { headers: Headers }).then((response) => {
      localStorage.setItem('begin_user', JSON.stringify(response.data.data));
      localStorage.setItem('accountID', email);

      callback(true);
    });
  } catch (e) {
    callback(false);
  }
}

export async function getCustomerDetailsAPI(
  email: string,
  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'accounts';
  const data = JSON.stringify({
    operation: 'read',
    payload: {
      accountID: email,
    },
  });

  try {
    axios.post(url, data, { headers: Headers }).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data) &&
        response.data.data.length > 0
      ) {
        ReactGA.event({
          category: 'Profiles',
          label: response.data.data[0],
          action: 'Prof_Select_Success',
          nonInteraction: false,
        });
        ReactPixel.track('Prof_Select_Success');
        callback(response.data.data[0]);
      } else {
        ReactGA.event({
          category: 'Profiles',
          label: 'false',
          action: 'Prof_Select_Failed',
          nonInteraction: false,
        });
        ReactPixel.track('Prof_Select_Failed');
        callback(false);
      }
    });
  } catch (e) {
    callback(false);
  }
}

export async function updateCustomerDetailsAPI(
  attributes: any,
  callback: (response: any) => void
) {
  const url = appEndpoints.prod + 'accounts';
  const data = JSON.stringify({
    operation: 'update',
    payload: {
      accountID: localStorage.getItem('accountID'),
      attributes: attributes,
    },
  });

  try {
    axios
      .patch(url, data, { headers: Headers })
      .then((response) => {
        ReactGA.event({
          category: 'Profiles',
          label: data,
          action: 'Prof_CPSave_Initiate',
          nonInteraction: false,
        });
        ReactPixel.track('Prof_CPSave_Initiate');
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          ReactGA.event({
            category: 'Profiles',
            label: response.data.data[0],
            action: 'Prof_CPSave_Success',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_CPSave_Success');
          callback(response.data.data[0]);
        } else {
          ReactGA.event({
            category: 'Profiles',
            label: 'false',
            action: 'Prof_CPSave_Failed',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_CPSave_Failed');
          callback(false);
        }
      })
      .catch(function (error) {
        ReactGA.event({
          category: 'Profiles',
          label: error.response.status,
          action: 'Prof_Add_Failed',
          nonInteraction: false,
        });
        ReactPixel.track('Prof_Add_Failed');
        callback(false);
      });
  } catch (e) {
    console.error(e);
    callback(false);
  }
}

export async function updateCustomerStatus(callback: (response: any) => void) {
  const url = appEndpoints.prod + 'accounts';
  const data = JSON.stringify({
    operation: 'update',
    payload: {
      accountID: localStorage.getItem('accountID'),
      attributes: {
        accountStatus: 'deleted',
      },
    },
  });

  try {
    axios
      .patch(url, data, { headers: Headers })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          callback(response.data.data[0]);
        } else {
          callback(false);
        }
      })
      .catch(function (error) {
        callback(false);
      });
  } catch (e) {
    console.error(e);
    callback(false);
  }
}
