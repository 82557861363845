import React, { lazy, useEffect, useState } from 'react';
import { createBrowserRouter, useNavigate } from 'react-router-dom';
import Auth from '../views/Auth/Auth';
import OTP from '../views/Auth/OTP/OTP';
import Home from '../views/Home/Home';
import UserHome from '../views/UserHome/UserHome';
import ForgotPassword from '../views/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../views/Auth/ResetPassword/ResetPassword';
import AvailableSubscriptions from '../views/Auth/AvailableSubscriptions/AvailableSubscriptions';
import NewAvailableSubscriptions from '../views/NewAvailableSubscriptions/NewAvailableSubscriptions';
import { SubscriptionFineTuning } from '../views/Auth/SubscriptionFineTuning/SubscriptionFineTuning';
import { Payment } from '../views/Auth/Payment/Payment';
import { HomeComponentsEnum } from '../types/local-models/home.view';
import { LoginFunc, SignupFunc } from './account-child-routes';
import RootLayout from '../views/RootLayout/RootLayout';
import { checkAuthLoader, tokenLoader } from './auth';
import SubscriptionStatus from '../views/Auth/SubscriptionStatus/SubscriptionStatus';
import { ReactNode } from 'react';
import { ScreenComponents } from '../types/local-models/begin-views';
import UserSearch from '../views/UserSearch/UserSearch';
import UserSettings from '../views/UserSettings/UserSettings';
import HubspotContactForm from '../views/HubspotContactForm/HubspotContactForm';
import Logout from '../views/Auth/Logout/Logout';

import Profile from '../views/Auth/Profile/Profile';
import PaymentMethods from '../views/Auth/PaymentMethods/PaymentMethods';
import Settings from '../views/Settings/Settings';
import MobileLogin from '../views/MobileLogin/MobileLogin';
import RefreshToken from '../views/RefreshToken/refreshToken';
import NotFound from '../views/NotFound/NotFound';
import DownloadApp from '../components/downloadApp/downloadApp';
import NewPayment from '../views/NewPayment/NewPayment';
import ContentDetails2 from '../components/content-details-2/ContentDetails2';

const router = () => {
  const routes = createBrowserRouter([
    { path: '/', element: <Home component={HomeComponentsEnum.home} /> },
    {
      path: '/privacy-policy',

      element: <Home component={HomeComponentsEnum.privacy} />,
    },
    {
      path: '/privacy-policy-mobile',

      element: (
        <Home
          component={HomeComponentsEnum.privacy}
          isMobile={true}
        />
      ),
    },
    {
      path: '/terms-and-conditions',

      element: <Home component={HomeComponentsEnum.terms} />,
    },
    {
      path: '/terms-and-conditions-mobile',

      element: (
        <Home
          component={HomeComponentsEnum.terms}
          isMobile={true}
        />
      ),
    },
    {
      path: '/user-search',
      children: [
        {
          path: '/user-search',
          element: <UserSearch />,
        },
        {
          path: '/user-search/:contentType/:contentID',
          // element: <ContentDetails2  />,
          element: (
            <UserSearch
              contentType={':contentType'}
              contentID={':contentID'}
            />
          ),
        },
      ],
    },
    { path: '/user-settings', element: <UserSettings /> },
    {
      path: '/support',
      element: <HubspotContactForm />,
    },
    {
      path: '/profile-load',
      element: <Profile type="load" />,
      loader: checkAuthLoader || null,
    },
    {
      path: '/profiles',
      loader: checkAuthLoader || null,
      children: [
        {
          path: '/profiles/',
          element: <Profile type="select" />,
          loader: checkAuthLoader || null,
        },
        {
          path: '/profiles/add-profile',
          element: (
            <Profile
              type="select"
              addProfile={true}
            />
          ),
          loader: checkAuthLoader || null,
        },
        {
          path: '/profiles/edit-profile/:profileID',
          element: (
            <Profile
              type="select"
              editProfile={':profileID'}
            />
          ),
          loader: checkAuthLoader || null,
        },
        {
          path: '/profiles/delete-profile/:profileID',
          element: (
            <Profile
              type="delete"
              deleteProfile={':profileID'}
            />
          ),
          loader: checkAuthLoader || null,
        },
      ],
    },
    {
      path: '/movies',
      loader: checkAuthLoader || null,
      children: [
        {
          path: '/movies',
          loader: checkAuthLoader || null,
          element: (
            <UserHome
              component={ScreenComponents.movies}
              contentType={'movies'}
            />
          ),
        },
        {
          path: '/movies:contentID',
          loader: checkAuthLoader || null,
          element: (
            <UserHome
              contentID={':contentID'}
              contentType={'movies'}
              component={ScreenComponents.movies}
            />
          ),
        },
      ],
    },
    {
      path: '/tv-shows',
      loader: checkAuthLoader || null,
      children: [
        {
          path: '/tv-shows',
          element: (
            <UserHome
              component={ScreenComponents['tv-shows']}
              contentType={'tv-shows'}
            />
          ),
          loader: checkAuthLoader || null,
        },
        {
          path: '/tv-shows:contentID',
          loader: checkAuthLoader || null,
          element: (
            <UserHome
              contentID={':contentID'}
              contentType={'tv-shows'}
              component={ScreenComponents['tv-shows']}
            />
          ),
        },
      ],
    },
    {
      path: '/sports',
      loader: checkAuthLoader || null,
      children: [
        {
          path: '/sports/',
          loader: checkAuthLoader || null,
          element: (
            <UserHome
              contentID={':contentID'}
              contentType={'sports'}
              component={ScreenComponents.sports}
            />
          ),
        },
        {
          path: '/sports:contentID',
          loader: checkAuthLoader || null,
          element: (
            <UserHome
              contentID={':contentID'}
              contentType={'sports'}
              component={ScreenComponents['sports']}
            />
          ),
        },
      ],
    },
    // {
    //   path: '/explore',
    //   element: <UserHome component={ScreenComponents.explore} />,
    //   loader: checkAuthLoader || null,
    //   children: [
    //     {
    //       path: '/explore/',
    //       loader: checkAuthLoader || null,
    //       element: (
    //         <UserHome
    //           contentID={':contentID'}
    //           contentType={'explore'}
    //           component={ScreenComponents.explore}
    //         />
    //       ),
    //     },
    //   ],
    // },
    {
      path: '/user-search',
      element: <UserSearch />,
      loader: checkAuthLoader || null,
    },
    {
      path: '/user-settings',
      element: <UserSettings />,
      loader: checkAuthLoader || null,
    },
    {
      path: '/settings',
      loader: checkAuthLoader || null,
      children: [
        {
          path: '/settings',
          element: <Settings module="" />,
          loader: checkAuthLoader || null,
        },
        {
          path: '/settings/account',
          loader: checkAuthLoader || null,
          children: [
            {
              path: '/settings/account',
              element: <Settings module="account" />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/settings/account/edit-phone-number',
              element: <Settings module="phone" />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/settings/account/edit-password',
              element: <Settings module="password" />,
              loader: checkAuthLoader || null,
            },
          ],
        },
        {
          path: '/settings/subscriptions/:status?',
          element: <Settings module="subscriptions" />,
          loader: checkAuthLoader || null,
        },
        {
          path: '/settings/devices',
          element: <Settings module="devices" />,
          loader: checkAuthLoader || null,
        },
        {
          path: '/settings/help',
          element: <Settings module="help" />,
          loader: checkAuthLoader || null,
        },
      ],
    },
    {
      path: '/',
      element: <RootLayout />,
      loader: tokenLoader || null,
      id: 'root',
      children: [
        {
          index: true,
          element: <Home component={HomeComponentsEnum.home} />,
        },
        {
          path: '/privacy-policy',
          element: <Home component={HomeComponentsEnum.privacy} />,
        },
        {
          path: '/terms-and-conditions',
          element: <Home component={HomeComponentsEnum.terms} />,
        },
        {
          path: '/account',
          element: <Auth />,
          children: [
            { path: '/account/', lazy: LoginFunc },
            { path: '/account/login', lazy: LoginFunc },
            { path: '/account/create-account', lazy: SignupFunc },
            { path: '/account/signup', lazy: SignupFunc },
            { path: '/account/otp', element: <OTP /> },
            { path: '/account/forgot-password', element: <ForgotPassword /> },
            { path: '/account/reset-password', element: <ResetPassword /> },

            {
              path: '/account/available-subscriptions',
              loader: checkAuthLoader || null,
              element: <AvailableSubscriptions />,
            },
            {
              path: '/account/available-subscriptions/:contentType/:contentID',
              loader: checkAuthLoader || null,
              element: <AvailableSubscriptions flowType="getContent" />,
            },

            {
              path: '/account/available-subscriptions/:contentType/:seasonID/:contentID',
              loader: checkAuthLoader || null,
              element: <AvailableSubscriptions flowType="getContent" />,
            },
            {
              path: '/account/select-subscription-duration',
              element: <SubscriptionFineTuning />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/account/payment-methods',
              element: <PaymentMethods />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/account/payment',
              element: <Payment />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/account/logout',
              loader: checkAuthLoader || null,
              element: <Logout />,
            },
          ],
        },
        {
          path: '/live',
          element: <Auth type="live" />,
          children: [
            { path: '/live/', lazy: SignupFunc },
            { path: '/live/login', lazy: LoginFunc },
            { path: '/live/create-account', lazy: SignupFunc },
            { path: '/live/signup', lazy: SignupFunc },
            { path: '/live/otp', element: <OTP /> },
            { path: '/live/forgot-password', element: <ForgotPassword /> },
            { path: '/live/reset-password', element: <ResetPassword /> },
            {
              path: '/live/subscription-status',
              element: <SubscriptionStatus />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/live/available-subscriptions',
              element: <AvailableSubscriptions />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/live/select-subscription-duration',
              element: <SubscriptionFineTuning />,
              loader: checkAuthLoader || null,
            },
            {
              path: '/live/payment',
              element: <Payment />,
              loader: checkAuthLoader || null,
            },
          ],
        },
        {
          path: '/mobile-login/:idtoken/:type/:id/:seasonid?',
          element: <MobileLogin />,
        },
        {
          path: '/new-available-subscriptions',
          loader: checkAuthLoader || null,
          element: <NewAvailableSubscriptions />,
        },
        {
          path: '/new-available-subscriptions/:contentType/:seasonID/:contentID',
          loader: checkAuthLoader || null,
          element: <NewAvailableSubscriptions flowType="getContent" />,
        },
        {
          path: '/new-available-subscriptions/:contentType/:contentID',
          loader: checkAuthLoader || null,
          element: <NewAvailableSubscriptions flowType="getContent" />,
        },
        {
          path: '/new-payment',
          loader: checkAuthLoader || null,
          element: <NewPayment />,
        },
      ],
    },
    {
      path: '/refresh-token',
      element: <RefreshToken />,
    },
    {
      path: '/download-app',
      element: <DownloadApp />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return routes;
};

export default router;
