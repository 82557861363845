import React from 'react';
import styles from './progress-dots.module.css';
import { ProgressDotsModel } from '../../types/local-models/progress-dots.component.model';

function ProgressDots(props: ProgressDotsModel) {
  const dots = [];
  for (let i=0; i<props.dots; i++) {
    let style = styles.dot;

    if (i == props.activeIndex) {
      style = styles.dot + ' ' + styles.activeDot;
    }

    dots.push(<div key={'dot-' + i} className={style}></div>);
  }
  return (
    <div className={ styles.container }>
      { dots }
    </div>
  );
}

export default ProgressDots;