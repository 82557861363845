import React, { useState } from 'react';
import BeginInput from '../../../../components/form-input-fields/begin-input';
import BeginButton from '../../../../components/begin-button/begin-button';

import styles from '../../SettingsModules.module.css';
import { updateCognitoPhoneNumber } from '../../../../services/Cognito';

import { regex, errorMessages } from '../../../Auth/auth-child-constants';
import BeginToast from '../../../../components/begin-toast/begin-toast';
import Loading from '../../../../components/Loading/Loading';
import { updateCustomerDetailsAPI } from '../../../../services/customer-details';

import { useNavigate } from 'react-router-dom';

import { FaChevronLeft } from 'react-icons/fa';

const PhoneNumber: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState(' ');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();
  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  const newPhoneSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = document.getElementById('form') as HTMLFormElement;

    if (form?.checkValidity()) {
      setSubmitted(true);

      await updateCognitoPhoneNumber(
        '+92' + phoneNumber,
        password,
        (err, result) => {
          if (err) {
            setError(err.message);
            setSubmitted(false);

            setTimeout(() => {
              setError('');
            }, 5000);
          } else {
            updateCustomerDetailsAPI(
              { accountPhone: '+92' + phoneNumber },
              (response) => {
                setSubmitted(false);

                if (response !== false) {
                  setError('');
                  setSuccess('Phone number updated successfully');
                } else {
                  setError('Error updating phone number');

                  setTimeout(() => {
                    setError('');
                  }, 5000);
                }
              }
            );
          }
        }
      );
    }
  };

  return (
    <>
      <div className={styles.back} onClick={() => navigate(-1)}>
        <FaChevronLeft />
      </div>
      <Loading show={submitted} />
      <div className={styles.childModule}>
        <h2>Update Phone Number</h2>
        <form onSubmit={newPhoneSubmit} id="form" autoComplete="off">
          <label className={styles.formInput}>
            New Phone Number:
            <BeginInput
              type="tel"
              placeholder="Mobile"
              value={phoneNumber}
              id="phoneNumber"
              name="phoneNumber"
              required={true}
              pattern="3\d{9}"
              autoComplete="tel-national"
              onChange={handleChange}
            />
          </label>

          <label className={styles.formInput}>
            Email Address Associated with Account:
            <BeginInput
              type="email"
              placeholder="Email Address"
              value={localStorage.getItem('accountID') || ''}
              id="email"
              name="email"
              required={true}
              readonly={true}
              autoComplete="email"
            />
          </label>

          <label className={styles.formInput}>
            Password:
            <BeginInput
              type="password"
              placeholder="Password"
              value={password}
              id="password"
              name="password"
              required={true}
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </label>

          <BeginButton
            type="submit"
            datalabel="Submit"
            className="primary"
            to=""
          />

          <div
            onClick={() => {
              setError('');
            }}
          >
            <BeginToast
              text={error}
              type="error"
              shown={error !== '' && error !== null ? true : false}
              dismissduration={5000}
            />
          </div>

          <div
            onClick={() => {
              setSuccess('');
            }}
          >
            <BeginToast
              text={success}
              type="success"
              shown={success !== '' && success !== null ? true : false}
              dismissduration={5000}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default PhoneNumber;
