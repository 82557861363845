import React, { FC, useEffect } from 'react';
import styles from './HubspotContactForm.module.css';
import ReactPixel from 'react-facebook-pixel';
import logo from '../../assets/images/logo.png';
import ReactGA from 'react-ga4';

declare global {
  interface Window {
    hbspt: any;
  }
}

const HubspotContactForm: FC = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'Settings-Help',
      action: 'SH_View',
      nonInteraction: false,
    });
    ReactPixel.track('SH_View');
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      ReactGA.event({
        category: 'Settings-Help',
        action: 'SH_FormSubmit_Initiate',
        nonInteraction: false,
      });
      ReactPixel.track('SH_FormSubmit_Initiate');
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        try {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '44890526',
            formId: '27408ec8-c084-4caf-aad7-3886c93a3fea',
            target: '#hubspotForm',
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  }, []);

  return (
    <div className={styles.hubspotForm}>
      <div className={styles.hubspotFormHeader}>
        <img src={logo} alt="logo" />
      </div>
      <h1>Support</h1>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
