import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import { useNavigate, useParams } from 'react-router-dom';
import './NewAvailableSubscriptions.css';
import {
  SubscriptionsAPI,
  addCustomerSubscriptions,
} from '../../services/subscriptions';
import { subscriptionDetails } from '../../types/remote-dtos/subscriptions.dto';
import { tvShowsApi } from '../../services/content-apis/tvShows';
import { MoviesApi } from '../../services/content-apis/movies';
import { sportsApi } from '../../services/content-apis/sports';
import descriptiveText from '../../assets/images/descriptive-text.png';
import BeginButton from '../../components/begin-button/begin-button';
import discountImg from '../../assets/images/subscriptions/discount.png';

interface AvailableSubscriptionsProps {
  flowType?: 'getContent' | 'normal';
}

const NewAvailableSubscriptions: FC<AvailableSubscriptionsProps> = ({
  flowType,
}: AvailableSubscriptionsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contentType, contentID, seasonID } = useParams<{
    contentType: string;
    contentID: string;
    seasonID?: string;
  }>();
  const [formValid, setFormValid] = useState(false);
  const [subscriptionsList, setSubscriptionsList] = useState<
    subscriptionDetails[]
  >([]);
  const [selectedSubscriptionID, setSelectedSubscriptionID] = useState('');
  const [selectedSubscriptions, setSelectedSubscriptions] = useState('');
  const [costs, setCosts] = useState(0);
  const [subscriptionDurations, setSubscriptionDurations] = useState<any[]>([]);
  const [baseURL, setBaseUrl] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [currentPricingIndex, setCurrentPricingIndex] = useState(0);

  useEffect(() => {
    if (window.location.href.includes('live')) {
      setBaseUrl('live');
    } else {
      setBaseUrl('account');
    }

    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);

    dispatch(landingActions.hideToast());
    dispatch(landingActions.showLoader());

    if (flowType === 'getContent' && contentType && contentID) {
      switch (contentType) {
        case 'movies':
          MoviesApi.getMovie(contentID, 'movie').then((response: any) => {
            dispatch(landingActions.hideLoader());
            if (
              response &&
              response.streamLink &&
              response.streamLink.length > 0 &&
              response.streamLink[0].subscriptions
            ) {
              setSubscriptionsList(response.streamLink[0].subscriptions);
            }
          });
          break;
        case 'tv-shows':
          tvShowsApi.getTVShow(contentID, 'tv-show').then((response: any) => {
            dispatch(landingActions.hideLoader());
            if (
              response &&
              response.streamLink &&
              response.streamLink.length > 0 &&
              response.streamLink[0].subscriptions
            ) {
              setSubscriptionsList(response.streamLink[0].subscriptions);
            }
          });
          break;
        case 'sports':
          sportsApi.getTVShow(contentID, 'tv-show').then((response: any) => {
            dispatch(landingActions.hideLoader());
            if (
              response &&
              response.streamLink &&
              response.streamLink.length > 0 &&
              response.streamLink[0].subscriptions
            ) {
              setSubscriptionsList(response.streamLink[0].subscriptions);
            }
          });
          break;
        case 'episode':
          if (seasonID && contentID) {
            sportsApi.getEpisode(seasonID, contentID).then((response: any) => {
              dispatch(landingActions.hideLoader());
              if (
                response &&
                response.length > 0 &&
                response[0].subscriptions
              ) {
                setSubscriptionsList(response[0].subscriptions);
              }
            });
          }
          break;
        default:
          console.log('Invalid content type');
      }
    } else {
      SubscriptionsAPI.getAll(null).then((response: any) => {
        setSubscriptionsList(response);
        dispatch(landingActions.hideLoader());
      });
    }
  }, [dispatch, flowType, contentType, contentID, seasonID]);

  const expandAndSelect = (item: subscriptionDetails) => {
    setSelectedSubscriptionID(item.subscriptionID);
    setSelectedSubscriptions(item.subscriptionTitle);
    setCosts(item.subscriptionPricing[0].price);
    setSubscriptionDurations(item.subscriptionPricing);
    setDiscountPercentage(
      item.subscriptionPricing[currentPricingIndex].discount
    );
    setFormValid(true);
  };

  const formatDate = (date: Date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const getOrdinalSuffix = (n: number) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const renderPopup = () => {
    if (!subscriptionsList.length) {
      return <div>No subscriptions available</div>;
    }

    return (
      <div className="subscription-popup-grid">
        {subscriptionsList.map((subscription) => {
          const price =
            subscription.subscriptionPricing[currentPricingIndex].price;
          const discount =
            subscription.subscriptionPricing[currentPricingIndex].discount;
          const originalPrice =
            subscription.subscriptionPricing[currentPricingIndex].originalPrice;
          return (
            <div
              className="subscription-popup"
              key={subscription.subscriptionID}
              onClick={() => expandAndSelect(subscription)}
            >
              {discount !== 0 && (
                <div className="dicount-percentage">
                  <img src={discountImg} alt="Discount" />
                  <p className="dicount-percentage-text">{discount}%</p>
                  <p className="dicount-off">OFF</p>
                </div>
              )}

              <div className="thumbnails-container">
                {subscription.subscriptionThumbnails &&
                  subscription.subscriptionThumbnails
                    .slice(0, 5)
                    .map(
                      (
                        thumbnail: string | undefined,
                        idx: React.Key | null | undefined
                      ) => (
                        <img
                          key={idx}
                          src={thumbnail}
                          alt={subscription.subscriptionTitle}
                          className="thumbnail-image"
                        />
                      )
                    )}
              </div>
              <div></div>
              <div className="Details-container">
                <h3>{subscription.subscriptionTitle}</h3>
                <p>{subscription.subscriptionDesc}</p>
                <img className="desImg" src={descriptiveText} />

                <div className="pricing-buttons">
                  {subscription.subscriptionPricing.map((pricing, idx) => (
                    <button
                      key={idx}
                      className={idx === currentPricingIndex ? 'active' : ''}
                      onClick={() => setCurrentPricingIndex(idx)}
                    >
                      {pricing.title}
                    </button>
                  ))}
                </div>
                {subscription.subscriptionPricing[currentPricingIndex].trial >
                  0 && (
                  <p className="trial">
                    Get your{' '}
                    {
                      subscription.subscriptionPricing[currentPricingIndex]
                        .trial
                    }{' '}
                    - day Free Trial
                  </p>
                )}
                <p>Billing starts from {formatDate(new Date())}</p>
                {discount !== 0 && (
                  <div className="price-container">
                    <p
                      className={`price ${
                        discount !== 0 ? 'original-price' : ''
                      }`}
                    >
                      {originalPrice} PKR
                    </p>
                    {<p className="price ">{price} PKR</p>}
                  </div>
                )}
                {discount == 0 && (
                  <div className="price-container">
                    <p className="price">{price} PKR</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="subscription-bg">
      <div className="overlay">
        <h1 className="titleTop">Choose your subscription</h1>
        {renderPopup()}
        {formValid && (
          <div className="btnCustom">
            <BeginButton
              className="secondary"
              datalabel="Next"
              to=""
              type="button"
              squared={true}
              tall={true}
              onClick={(event) => {
                event.stopPropagation();
                const selectedPricing =
                  subscriptionDurations[currentPricingIndex];
                const otherPricing = subscriptionDurations.find(
                  (pricing, idx) => idx !== currentPricingIndex
                );

                const selectedSubscription = {
                  selectedSubscriptions,
                  selectedSubscriptionID,
                  selectedPrice: selectedPricing.price,
                  otherPrice: otherPricing ? otherPricing.price : null,
                  selectedTrialDays: selectedPricing.trial,
                  subscriptionDurations,
                  selectedDiscount: selectedPricing.discount,
                  selectedBillingCycle: selectedPricing.billing,
                  originalPrice: selectedPricing.originalPrice,
                };

                navigate('/new-payment', {
                  state: {
                    selectedSubscription,
                    subscriptionList: subscriptionsList.find(
                      (sub) => sub.subscriptionID === selectedSubscriptionID
                    ),
                  },
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAvailableSubscriptions;
