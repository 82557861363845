import React, { useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import style from '../auth-child.module.css';
import avatarSelectionStyle from './avatars.module.css';
import BeginInput from '../../../components/form-input-fields/begin-input';
import BeginButton from '../../../components/begin-button/begin-button';
import { FaTimes, FaExclamationTriangle, FaPencilAlt } from 'react-icons/fa';

import { useParams, useNavigate } from 'react-router-dom';
import editIcon from '../../../assets/images/profile-icons/edit.png';
import { ProfileAPI } from '../../../services/profile';
import Loading from '../../../components/Loading/Loading';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

interface AddOrEditProfile {
  type: 'add' | 'edit';
  edit?: {
    profileID: string;
  };
  setShouldUpdate?: any;
}

const AddEditProfile = ({ type, edit, setShouldUpdate }: AddOrEditProfile) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    profileName: '',
    isForKids: false,
  });
  const [acctType, setAcctType] = useState('extra');
  const [profileLoading, setProfileLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    profileName: '',
  });

  const [error, setError] = useState('');

  const [avatarsLoading, setAvatarsLoading] = useState(false);
  const [avatar, setAvatar] = useState<string>('');
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  // Import avatar images
  const [groupedAvatars, setGroupedAvatars] = useState<any[]>([]);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleInputChange = (identifier: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      isForKids: event.target.checked,
    }));
  };

  const params = useParams();

  useEffect(() => {
    const checkLoggedIn = async () => {
      if (type === 'edit' && params.profileID) {
        setProfileLoading(true);
        ProfileAPI.getProfile(params.profileID).then((response) => {
          setProfileLoading(false);
          if (response.length > 0) {
            setFormValues({
              profileName: response[0].acctProfileName,
              isForKids: response[0].acctProfileForKids,
            });
            setAcctType(response[0].acctProfileType);
            setAvatar(response[0].acctProfileAvatar);
          } else {
            setError('The specified profile could not be found');
          }
        });
      } else if (type === 'add') {
        setAvatar(
          'https://s3.ap-southeast-1.amazonaws.com/begin.assets/avatars/black.webp'
        );
      }
    };

    checkLoggedIn();

    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);

    dispatch(landingActions.hideLoader());
    dispatch(landingActions.hideToast());
  }, [dispatch]);

  const getAvatars = () => {
    setAvatarsLoading(true);
    ProfileAPI.getAvatars().then((response) => {
      setAvatarsLoading(false);
      console.log('avatars', response);
      setGroupedAvatars(response);
    });
  };
  const logGAcancel = () => {
    ReactGA.event({
      category: 'Profiles',
      action: 'Prof_CPSave_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('Prof_CPSave_Initiate');
  };
  const handleEditIconClick = () => {
    getAvatars();
    setIsOverlayOpen(true);
  };
  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  const handleSelectAvatar = (selectedAvatar: string) => {
    setAvatar(selectedAvatar);
    setIsOverlayOpen(false);
  };

  const saveProfile = () => {
    if (formValues.profileName.length >= 2 && !buttonDisabled) {
      setButtonDisabled(true);
      if (type === 'add') {
        ProfileAPI.add({
          name: formValues.profileName,
          avatar: avatar,
          forKids: formValues.isForKids,
          type: 'extra',
        }).then((createProfileResponse) => {
          setShouldUpdate(true);
          ReactGA.event({
            category: 'Profiles',
            action: 'Prof_Add_Success',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_Add_Success');
          window.setTimeout(() => {
            navigate('/profiles');
          }, 500);
        });
      } else if (type === 'edit' && params.profileID) {
        ProfileAPI.update({
          profileID: params.profileID,
          name: formValues.profileName,
          avatar: avatar,
          forKids: formValues.isForKids,
          type: acctType,
        }).then((createProfileResponse) => {
          setShouldUpdate(true);
          // setButtonDisabled(false);
          ReactGA.event({
            category: 'Profiles',
            action: 'Prof_EPSave_Success',
            nonInteraction: false,
          });
          ReactPixel.track('Prof_EPSave_Success');
          window.setTimeout(() => {
            navigate('/profiles');
          }, 500);
        });
      }
    } else {
      setButtonDisabled(false);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        profileName: 'Profile name must be at least 2 characters long',
      }));
    }
  };

  return (
    <div className={`${style.authContainer}`}>
      {error === '' && (
        <form className={`${style.form} ${style.addEditProfileForm}`}>
          <Loading show={profileLoading} />
          <div className={style.authContent}>
            <h1 className="title">
              {type === 'add' ? 'Create Profile' : 'Edit Profile'}
            </h1>

            <div className={style.group}>
              <div className="preHeading">Profile</div>
              {/* <h1>
                {type === 'add' ? 'Create your profile' : 'Edit your profile'}
              </h1> */}
              <div className={style.titleLabel}>
                {type === 'add'
                  ? 'You can always edit your profiles in the Me section'
                  : ''}
              </div>
              <div className={style.separator}></div>
            </div>

            {/* Image for Profile Picture */}
            <div>
              <div className={`${style.addUpdateProfileImgRow}`}>
                <img
                  src={avatar}
                  alt=""
                  className={style.addUpdateProfileImg}
                />
                <div
                  className={style.editIcon}
                  onClick={() => handleEditIconClick()}
                >
                  <FaPencilAlt />
                </div>
              </div>
            </div>

            {/* Profile Name Input */}
            <div className={style.profileInput}>
              <BeginInput
                type="text"
                placeholder="Profile Name"
                name="profile_name"
                id="profile_name"
                required={true}
                minlength={2}
                maxlength={20}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length <= 20) {
                    handleInputChange('profileName', event?.target?.value);
                  }
                }}
                value={formValues.profileName}
                error_message={formErrors.profileName}
              />
            </div>

            {/* <div className={style.group}>
              <input
                type="checkbox"
                id="for_kids"
                name="for_kids"
                checked={formValues.isForKids}
                onChange={handleCheckboxChange}
              />
              <label
                className={style.checkboxLable}
                htmlFor="for_kids"
              >
                Yes, this profile is for kids
              </label>
            </div> */}
            <div className={style.buttonGroup}>
              <BeginButton
                preset="button"
                type="button"
                className="primary"
                to=""
                datalabel="Save"
                onClick={saveProfile}
                disabled={buttonDisabled}
              />
              <BeginButton
                preset="link"
                type="button"
                className="link"
                to="/profiles"
                datalabel="Cancel"
                onClick={logGAcancel}
              />
            </div>
          </div>
        </form>
      )}

      {error !== '' && (
        <div style={{ boxSizing: 'border-box' }}>
          <div className={style.notFound}>
            <FaExclamationTriangle className={style.errorIcon} />
            <br />
            {error}
          </div>
          <BeginButton
            preset="link"
            type="button"
            className="secondary"
            to="/profiles"
            datalabel="Back to Profiles"
          />
        </div>
      )}

      {/* Avatar Selection Overlay */}
      <div
        className={`${avatarSelectionStyle.avatarOverlay} ${
          isOverlayOpen ? avatarSelectionStyle.showAvatarOverlay : ''
        }`}
      >
        {/* Close icon */}
        <button
          className={avatarSelectionStyle.closeIcon}
          onClick={handleCloseOverlay}
        >
          <FaTimes />
        </button>

        <Loading show={avatarsLoading} />

        <div className={avatarSelectionStyle.overlayContent}>
          <h1 className={avatarSelectionStyle.choose} key={Math.random()}>
            Choose Avatar
          </h1>

          <div className={avatarSelectionStyle.avatarsInnerContainer}>
            {groupedAvatars.length > 0 &&
              groupedAvatars.map((avatarsGroup, index) => (
                <>
                  <h2
                    className={avatarSelectionStyle.title}
                    key={avatarsGroup.type}
                  >
                    {avatarsGroup.type}
                  </h2>
                  <div
                    className={avatarSelectionStyle.avatarGridContainer}
                    key={avatarsGroup.type + '-avatars-' + index}
                  >
                    {avatarsGroup.avatars.map((avatarEntry: any) => (
                      <div
                        key={
                          avatarsGroup.type + '-avatar-' + avatarEntry.avatarID
                        }
                        className={`${avatarSelectionStyle.avatarItem} ${
                          avatar === avatarEntry.avatarLink ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleSelectAvatar(avatarEntry.avatarLink)
                        }
                      >
                        <img
                          key={
                            avatarsGroup.type +
                            '-avatarImg-' +
                            avatarEntry.avatarID
                          }
                          className={avatarSelectionStyle.Avatar}
                          src={avatarEntry.avatarLink}
                          alt={`Avatar ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditProfile;
