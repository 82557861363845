import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  ISignUpResult,
} from 'amazon-cognito-identity-js';

import { CognitoJwtVerifier } from 'aws-jwt-verify';

import poolData from '../cognito-pool-data.json';
import type { LoaderFunction } from 'react-router-dom';
import { LoaderFunction as lf } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { WifiFind } from '@mui/icons-material';

const userPool = new CognitoUserPool(poolData);

export const createUser = async (
  accountID: string,
  phone: string,
  password: string,
  callback: (err: any, result: any) => void
) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: 'email',
      Value: accountID.toLowerCase(),
    }),
    new CognitoUserAttribute({
      Name: 'phone_number',
      Value: phone,
    }),
  ];

  userPool.signUp(
    accountID.toLowerCase(),
    password,
    attributeList,
    [],
    callback
  );
};

export const verifyUser = (
  accountID: string,
  verifyCode: string,
  callback: (err: any, result: any) => void
) => {
  const userData = {
    Username: accountID.toLowerCase(),
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(verifyCode, true, callback);
};

export const forgotPassword = async (
  accountID: string,
  callback: (err: any, result: any) => void
) => {
  const userData = {
    Username: accountID.toLowerCase(),
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess: function (result) {
      callback(null, result);
    },
    onFailure: function (err) {
      callback(err, null);
    },
  });
};

export const resetPassword = async (
  accountID: string,
  password: string,
  otpCode: string,
  callback: (err: any, result: any) => void
) => {
  const userData = {
    Username: accountID.toLowerCase(),
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(otpCode, password, {
    onSuccess: (result) => {
      callback(null, result);
    },
    onFailure: (err) => {
      callback(err, null);
    },
  });
};

export const loginUser = async (
  accountID: string,
  password: string,
  callback: (err: any, result: any) => void
) => {
  const authData = {
    Username: accountID.toLowerCase(),
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);

  const userData = {
    Username: accountID.toLowerCase(),
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess: (result) => {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          callback(err, null);
        } else {
          callback(null, result);
        }
      });
    },
    onFailure: (err) => {
      callback(err, null);
    },
  });
};

export const resendOTP = async (
  accountID: string,
  password: string,
  callback: (err: any, result: any) => void
) => {
  const authData = {
    Username: accountID.toLowerCase(),
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);

  const userData = {
    Username: accountID.toLowerCase(),
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode((err, result) => {
    if (err) {
      callback(err, null);
    } else {
      callback(null, result);
    }
  });
};

export function getAuthToken() {
  const cognitoUser = userPool.getCurrentUser();

  console.log('cognitoUser', cognitoUser);

  if (cognitoUser != null) {
    return cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        return null;
      } else {
        return session.getIdToken().getJwtToken();
      }
    });
  } else {
    if(localStorage.getItem('aToken')) {
      return localStorage.getItem('aToken');
    }
    return null;
  }
}

export async function refreshCognitoToken(
  callback: (err: any, result: any) => void
) {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    return cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        try {
          const signedInUser = cognitoUser.getSignInUserSession();
          let refreshToken = null;
          if (signedInUser) {
            refreshToken = signedInUser.getRefreshToken();

            if (refreshToken) {
              cognitoUser.refreshSession(
                refreshToken,
                (refreshErr, refreshSession) => {
                  if (refreshErr) {
                    callback(refreshErr, null);
                  } else {
                    callback(null, refreshSession.getIdToken().getJwtToken());
                  }
                }
              );
            } else {
              callback(err, null);
            }
          } else {
            callback(null, null);
          }
        } catch (error) {
          callback(error, null);
        }
        callback(null, null);
      } else {
        callback(null, session.getIdToken().getJwtToken());
      }
    });
  } else {
    callback(null, null);
  }
}

export function logout() {
  const cognitoUser = userPool.getCurrentUser();
  localStorage.removeItem('aToken');

  if (cognitoUser != null) {
    cognitoUser.signOut();
  }
}

export async function getUserByIDToken(idToken: string) {
  const verifier = CognitoJwtVerifier.create({
    userPoolId: poolData.UserPoolId,
    tokenUse: 'id',
    clientId: poolData.ClientId,
  });

  // If no error is thrown it is a valid token
  try {
    const payload = await verifier.verify(idToken);
    return payload;
  } catch (err) {
    return 'Invalid Token';
  }
}

export const updateCognitoPhoneNumber = async (
  newPhoneNumber: string,
  password: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  const authDetails = new AuthenticationDetails({
    Username: cognitoUser?.getUsername() || '',
    Password: password,
  });

  if (cognitoUser != null) {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const attributeList = [];
        const attribute = new CognitoUserAttribute({
          Name: 'phone_number',
          Value: newPhoneNumber,
        });
        attributeList.push(attribute);

        cognitoUser.updateAttributes(
          attributeList,
          (err, attributeUpdateResult) => {
            if (err) {
              callback(err, null);
            } else {
              callback(null, attributeUpdateResult);
            }
          }
        );
      },
      onFailure: (err) => {
        callback(err, null);
      },
    });
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  const authDetails = new AuthenticationDetails({
    Username: cognitoUser?.getUsername() || '',
    Password: oldPassword,
  });

  if (cognitoUser != null) {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          (err, changePasswordResult) => {
            if (err) {
              callback(err, null);
            } else {
              callback(null, changePasswordResult);
            }
          }
        );
      },
      onFailure: (err) => {
        callback(err, null);
      },
    });
  }
};

export const GetDeviceList = async (
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        callback(err, null);
      } else {
        cognitoUser.listDevices(20, null, {
          onSuccess: (result) => {
            callback(null, result);
          },
          onFailure: (devicesError) => {
            callback(devicesError, null);
          },
        });
      }
    });
  }
};

export const deleteUser = async (callback: (err: any, result: any) => void) => {
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        callback(err, null);
      } else {
        cognitoUser.deleteUser(callback);
      }
    });
  }
};
